import React, { useRef, useState } from 'react';
// import flower1 from "../FrameDecorationAssets/flower1.png"
import { useLocation } from 'react-router-dom';
import calender from "../FrameDecorationAssets/calender.jpeg";
// import clock from "../FrameDecorationAssets/clock.png";
import weightCal from "../FrameDecorationAssets/weight-calculator.jpg";
// import Hospital from "../FrameDecorationAssets/Hospital.jpeg"
import gender from "../FrameDecorationAssets/gender.jpeg";
// import redflower from "../FrameDecorationAssets/red-flower.png";
// import leftCornerFlower from "../FrameDecorationAssets/LeftCornerRedFlower.png";
// import rightCornerFlower from "../FrameDecorationAssets/RightCornerRedFlower.png";
import BoyDoll from "../FrameDecorationAssets/boydoll.png";
import doll from "../FrameDecorationAssets/doll.png";
import Calender from "../FrameDecorationAssets/Calender4.png";
import Hospital from "../FrameDecorationAssets/hospital.png";
import clock from "../FrameDecorationAssets/clock1.png";
import parents from "../FrameDecorationAssets/parentsImage.webp";
import teddy from "../FrameDecorationAssets/teddy.webp";
import moment from "moment"

const ThirdA5 = () => {
  const { state } = useLocation();
  console.log(state.state.item.framedata[0]);
  let state1 = state.state.item.framedata[0];


  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);
  const [initialX, setInitialX] = useState(0);
  const [initialY, setInitialY] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [zoomLevelP, setZoomLevelP] = useState(1);

  const [rotateFrame, setRotateFrame] = useState(0);
  const [rotateFrameP, setRotateFrameP] = useState(0);

  // Function to handle mouse down event
  const handleMouseDown = (e) => {
    // Store the initial mouse position and image position
    setOffsetX(e.clientX);
    setOffsetY(e.clientY);
    setInitialX(e.target.offsetLeft);
    setInitialY(e.target.offsetTop);
    setIsDragging(true);

    // Attach event listener to handle mouse move event
    document.addEventListener("mousemove", handleMouseMove);
    // Attach event listener to handle mouse up event
    document.addEventListener("mouseup", handleMouseUp);
  };


  const handleMouseMove = (e) => {
    if (isDragging) {

      const newX = initialX + e.clientX - offsetX;
      const newY = initialY + e.clientY - offsetY;
      console.log(newX);
      console.log(newY);
      e.target.style.left = newX + "px";
      e.target.style.top = newY + "px";
    }
  };

  // Function to handle mouse up event
  const handleMouseUp = () => {
    setIsDragging(false);
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  



  const imageRef = useRef(null);

  // Function to handle zoom based on mouse wheel event
  const handleZoomIn = (e) => {

    setZoomLevel(zoomLevel + 0.1); // Zoom in

  };

  const handleZoomInP = (e) => {

    setZoomLevelP(zoomLevelP + 0.1); // Zoom in

  };
  const handleZoomOut = (e) => {

    setZoomLevel(zoomLevel - 0.1); // Zoom out

  };
  const handleZoomOutP = (e) => {

    setZoomLevel(zoomLevel - 0.1); // Zoom out

  };




  const rotateImageLeft = (e) => {
    //  let rotate=rotate+2;
    setRotateFrame(rotateFrame + 2)
    // console.log(rotate)

    // image.style.transform =`rotate(${rotate}deg)`;
  }
  const rotateImageLeftP = (e) => {
    //  let rotate=rotate+2;
    setRotateFrameP(rotateFrameP + 2)
    // console.log(rotate)

    // image.style.transform =`rotate(${rotate}deg)`;
  }
  const rotateImageRight = (e) => {
    //  let rotate=rotate+2;
    setRotateFrame(rotateFrame - 2)
    // console.log(rotate)

    // image.style.transform =`rotate(${rotate}deg)`;
  }
  const rotateImageRightP = (e) => {
    //  let rotate=rotate+2;
    setRotateFrameP(rotateFrameP - 2)
    // console.log(rotate)

    // image.style.transform =`rotate(${rotate}deg)`;
  }
  return (
    <>
      <div className='designFrame' style={{ width: "100%", backgroundImage: {} }}>
        <div className='printme' style={{ width: "100%" }}>


          {/* <div className='col-12'> */}

          {/* <img src={redflower} className='' alt='' style={{ width: "100%", height: "180px", padding: "0" }} /> */}
          {/* <p className='satisfy-regular  ' style={{ fontSize: "6rem", marginBottom: "0", textAlign: "center", lineHeight: "1" }}> </p> */}
          <p className='itim-regular ' style={{ fontSize: "4.5rem", color: "#bf2b28", padding: "0", textAlign: "center", marginBottom: "0", lineHeight: "0.9" }}>{state1.baby_name}</p>

          <div style={{ display: "flex" ,height:"260px"}}>
            <div style={{ textAlign: "center", width: "33.33%" }}>
              {/* <p className='pacifico-regular' style={{ fontSize: "3rem", color: "#bf2b28" }}>Born In</p> */}
              <img src={BoyDoll} className='img-fluid ' alt='' style={{ width: "50%" }} />
              <img src={BoyDoll} className='img-fluid ' alt='' style={{ width: "52%" }} />
              <p className='pacifico-regular' style={{ fontSize: "1.5rem", color: "#bf2b28" }}>Gender</p>

              {/* <p className='pacifico-regular' style={{ fontSize: "3rem", color: "#bf2b28" }}>{moment(state1.dob).format('YYYY-MM-DD')}</p> */}
            </div>

            <div className='' style={{ display: "flex", justifyContent: "center", width: "33.33%" }} >
              {/* <img src="https://img.freepik.com/free-photo/cute-baby-born_624325-1181.jpg?t=st=1709460995~exp=1709464595~hmac=fafe5b8f262b6a904c034846872828e5385f37be0750875730aac8ea4d726af7&w=826" className='img-fluid   ' style={{objectFit:"cover",borderRadius:"20px",border:"7px solid #85208c",width:"300px",height:"350px"}} alt=''/> */}


              <div className='' style={{ position: "absolute", overflow: "hidden", borderRadius: "20px", width: "480px", height: "550px" }}>
              <i class="fa fa-rotate-right" onClick={()=>rotateImageLeft()} style={{position:"relative",top:"85px"}} ></i>
                <div class="octo" style={{width:"50%",height:"45%",margin:"0 auto", transformOrigin:"50% 50%"}}>
                  <div class="octo1" style={{width:"100%",height:"100%"}}>
                
                    <img src="https://img.freepik.com/free-photo/cute-baby-born_624325-1181.jpg?t=st=1709460995~exp=1709464595~hmac=fafe5b8f262b6a904c034846872828e5385f37be0750875730aac8ea4d726af7&w=826" alt="" onMouseDown={handleMouseDown} className='img-fluid baby-image  '  style={{
                  position: "relative", objectFit: "cover", cursor: "move",
                  transform: `scale(${zoomLevel}) rotate(${rotateFrame}deg)`, transformOrigin: "top left"
                }} ref={imageRef} onDoubleClick={handleZoomIn} onProgress={handleZoomOut}  />
                  </div>
                </div>
                <i class="fa fa-rotate-left" onClick={()=>rotateImageRight()} style={{position:"relative",bottom:"85px"}}></i>


                {/* <img src="https://img.freepik.com/free-photo/cute-baby-born_624325-1181.jpg?t=st=1709460995~exp=1709464595~hmac=fafe5b8f262b6a904c034846872828e5385f37be0750875730aac8ea4d726af7&w=826" onMouseDown={handleMouseDown} className='img-fluid baby-image  ' alt='' style={{
                  position: "relative", objectFit: "cover", cursor: "move",
                  transform: `scale(${zoomLevel}) rotate(${rotateFrame}deg)`, transformOrigin: "top left"
                }} ref={imageRef} onDoubleClick={handleZoomIn} onProgress={handleZoomOut} onClick={(e) => rotateImage(e)} /> */}

              </div>
            </div>

            <div style={{ textAlign: "center", width: "33.33%" }}>
              {/* <p className='pacifico-regular' style={{ fontSize: "3rem", color: "#bf2b28" }}>Born At</p> */}
              <img src={doll} className='img-fluid ' alt='clock' style={{ width: "50%" }} />
              <img src={doll} className='img-fluid ' alt='clock' style={{ width: "52%" }} />
              <p className='pacifico-regular' style={{ fontSize: "1.5rem", color: "#bf2b28" }}>Gender</p>
              {/* <p className='pacifico-regular' style={{ fontSize: "3rem", color: "#bf2b28" }}>{state1.baby_tob}</p> */}
            </div>

          </div>

          {/* <div className='col-12 p-0'> */}
          {/* <div  style={{display:"flex",justifyContent:"space-around",width:"100%"}}>
      <div className='' style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
        <div style={{textAlign:"center"}}>
        <p className='pacifico-regular' style={{fontSize:"3rem",color:"#bf2b28"}}>Born In</p>
        <img src={calender} className='img-fluid ' alt='' style={{width:"60%"}}/>
        </div>
        
      </div>
      <div className='' style={{display:"flex",justifyContent:"center"}} >
        <img src="https://img.freepik.com/free-photo/cute-baby-born_624325-1181.jpg?t=st=1709460995~exp=1709464595~hmac=fafe5b8f262b6a904c034846872828e5385f37be0750875730aac8ea4d726af7&w=826" className='img-fluid   ' style={{objectFit:"cover",borderRadius:"20px",border:"7px solid #85208c",width:"345px",height:"390px"}} alt=''/>
      </div>
      <div className='' style={{display:"flex",justifyContent:"center"}}>
        <div style={{textAlign:"center"}}>
        <p className='pacifico-regular' style={{fontSize:"3rem",color:"#bf2b28"}}>Born At</p>
        <img src={clock} className='img-fluid ' alt='clock' style={{width:"50%"}}/>
        </div>
    
      </div>

    </div> */}
          {/* </div> */}

          {/* <div className='col-12 p-0'> */}



          <div className='my-4' style={{ display: "flex" }}>
            <div style={{ textAlign: "center", width: "30%" }}>
              {/* <p className='pacifico-regular' style={{ fontSize: "3rem", color: "#bf2b28" }}>Weight</p> */}
              {/* <img src={Calender} className=' ' alt='' style={{ width: "52%" }} /> */}
              {/* <p className='pacifico-regular' style={{ fontSize: "3rem", color: "#bf2b28" }}>{state1.baby_weight}<span className='protest-riot-regular' style={{ fontSize: "2rem", color: "#bf2b28" }} >Kg</span></p> */}

              <img src={Calender} style={{width:"50%",marginTop:"35px"}}/>
              <p className='pacifico-regular' style={{ fontSize: "1.5rem", color: "#bf2b28" }}>Gender</p>
              <img src={teddy} style={{width:"50%",marginTop:"45px"}}/>
              <p className='pacifico-regular' style={{ fontSize: "1.5rem", color: "#bf2b28" }}>Gender</p>
            </div>

            <div className='' style={{position: "relative", overflow: "hidden", textAlign: "center", width: "40%" }} >
              {/* <p className='pacifico-regular' style={{ fontSize: "3rem", color: "#bf2b28" }}>Hospital</p> */}
              <img src={parents} className='' alt='hospital' style={{width: "100%",height:"300px",
                  position: "relative", objectFit: "cover", cursor: "move",
                  transform: `scale(${zoomLevelP}) rotate(${rotateFrameP}deg)`, transformOrigin: "top left"
                }} ref={imageRef}  onDoubleClick={handleZoomInP} onProgress={handleZoomOutP} />

              {/* <p className='protest-riot-regular' style={{ fontSize: "3rem", color: "#bf2b28" }}>{state1.hospital_name}</p> */}
            </div>

            <div style={{ textAlign: "center", width: "30%" }}>
              {/* <p className='pacifico-regular' style={{ fontSize: "3rem", color: "#bf2b28" }}>Gender</p> */}
              <img src={clock} className='img-fluid ' alt='clock' style={{ width: "50%",marginTop:"25px"}} />
              <p className='pacifico-regular' style={{ fontSize: "1.5rem", color: "#bf2b28" }}>Gender</p>
              {/* <p className='protest-riot-regular' style={{ fontSize: "3rem", color: "#bf2b28" }}>Baby {state1.baby_gender}</p> */}

              <img src={teddy} style={{width:"50%",marginTop:"25px"}}/>
              <p className='pacifico-regular' style={{ fontSize: "1.5rem", color: "#bf2b28" }}>Gender</p>
            </div>

          </div>

          {/* </div> */}

          {/* <div className='col-12 p-0'> */}
          <div className=''>
           
           
            <div className='' style={{ }} >
              {/* <div className='' style={{width:"60%"}}> */}
              <p className='pacifico-regular' style={{ fontSize: "2.2rem", color: "#bf2b28", textAlign: "center",marginBottom:"0px",position:"relative",top:"-105px"}}>Proud Parents</p>
          
              <p className='protest-riot-regular' style={{ fontSize: "2rem", color: "#bf2b28", padding: 0, lineHeight: "0.7", textAlign: "center",position:"relative",top:"-105px" }} contentEditable="true">Nirmala Kushwah & Sooraj kushwah</p>
            </div>
          </div>


          {/* ///////// */}
          {/* <div className='' style={{display:"flex"}}>
      <div style={{width:"20%",display:"flex"}} >
        <div style={{display:"flex",justifyContent:"start",alignItems:"end"}}>
        <img src={rightCornerFlower} className='img-fluid ' alt='clock' style={{width:"100%"}}/>

        </div>
      
      </div>
      <div className=''  style={{width:"60%",display:"flex",justifyContent:"center"}}>
       
        <img src="https://img.freepik.com/free-photo/cute-baby-born_624325-1181.jpg?t=st=1709460995~exp=1709464595~hmac=fafe5b8f262b6a904c034846872828e5385f37be0750875730aac8ea4d726af7&w=826" className='img-fluid   ' style={{objectFit:"cover",borderRadius:"20px",border:"7px solid #85208c",width:"40%",height:"350px"}} alt=''/>
        <div className='' style={{width:"60%"}}>
        <p className='pacifico-regular' style={{fontSize:"3.5rem",color:"#bf2b28",textAlign:"center"}}>Proud Parents</p>
        <p className='protest-riot-regular' style={{fontSize:"3rem",color:"#bf2b28",padding:0,lineHeight:"0.7",textAlign:"center"}}>
          Nirmala Kushwah</p>
          <p className='protest-riot-regular' style={{fontSize:"3rem",color:"#bf2b28",padding:0,lineHeight:"0.7",textAlign:"center"}}> &</p> 
           <p className='protest-riot-regular' style={{fontSize:"3rem",color:"#bf2b28",padding:0,lineHeight:"0.7",textAlign:"center"}}> Sooraj kushwah</p>
     


        </div>
       
      </div>
      <div className='' style={{width:"20%",display:"flex"}}>
        <div style={{display:"flex",justifyContent:"end",alignItems:"end"}}>
        <img src={leftCornerFlower} className='img-fluid p-0' alt='' style={{width:"100%"}}/>

        </div>
    
      </div>

    </div> */}

        </div>


      </div>


      <div>
        <button className='btn btn-primary' onClick={() => window.print()}> Print</button>
      </div>
    </>
  )
}

export default ThirdA5