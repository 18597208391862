import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import NavBar from "../../../NavBar";
import { Link, useNavigate } from "react-router-dom";

const StaticPageList=()=>{
    const BASE_URL=process.env.REACT_APP_BASE_URL;
    const token=localStorage.getItem("token");
    const [staticpginfo , setStaticpginfo]=useState([]);
    const navigate = useNavigate();
    
      const AddStaticPage = () => {
        const token = localStorage.getItem('token');
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}admin/addStaticPage`,
            {},
            {
              headers: {
                version: '1.0.0',
                'x-access-token': `${token}`,
                'Content-Type': 'Appliction/json',
              },
            }
          )
          .then((response) => {
            if (response.data.success) {
              console.log('static page added successfully');
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      };
    const getStaticpg=()=>{
        axios
        .post(`${BASE_URL}admin/getStaticPages`,{},{
            headers:{
                "Content-Type":"Application/json",
                "x-access-token":`${token}`,
                "version":"1.0.0"
            }
        }).then((response)=>{
            if(response.data.success===false)
            {
                if(response.data.error_code==461)
                {
                    navigate("/SignIn");
                }
                // toast.error(response.data.message);
            }else{
                // toast.success(response.data.message);
                setStaticpginfo(response.data.data);
                console.log(response.data.data);
            }
        }).catch((error)=>{
            console.log(error);
        })
    }

    const deletestcpg=(id)=>{
        if (window.confirm("Do you really want to delete this item?")) {
     
        } else {
         
            return ;
        }
        axios
        .delete(`${BASE_URL}admin/deleteStaticPage/${id}`,{
            headers:{
                "Content-Type":"Application/json",
                "version":"1.0.0",
                "x-access-token":`${token}`
            }
        }).then((response)=>{
            if(response.data.success==false)
            {
                if(response.data.error_code==461)
                {
                    navigate("/SignIn");
                }
                toast.error(response.data.message);
            }else{
                toast.error(response.data.message);
                getStaticpg();
            }
        }).catch((error)=>{
            console.log(error);
        })
    }
    useEffect(()=>{
        getStaticpg();  
        AddStaticPage();
    },[])

    return (
      <>
        <NavBar />
        <div className="row d-flex justify-content-end align-items-center ">
          {/* <div className="col-2  d-flex justify-content-center align-items-center">
                <Link to="/add-static-page"><button className="btn btn-primary mt-2">Add+</button></Link>
             <div><button className="btn btn-primary mt-2"></button></div> 
            </div> */}
        </div>
        <div className="container">
          <br />
          <br />
          <span
            style={{ fontSize: '20px', fontWeight: '600' }}
            className="pt-3"
          >
           Static Page List
          </span>
          <div className="scrollit pt-4">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>S no</th>
                  {/* <th>name</th> */}
                  <th> Name</th>
                  <th> description</th>
                  {/* <th>Short description</th> */}
                  <th>Status</th>
                  {/* <th>price</th> */}
                  {/* <th>Status</th> */}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {staticpginfo.map((item, index) => (
                  <tr key={index}>
                    {/* <td>1</td> */}
                    {/* <td><img src={item.image[0]} style={{ width: '40px' }}/></td> */}
                    <td className="tabletd">{++index}</td>

                    <td>{item.name}</td>
                    <td>{item.description}</td>
                    {/* <td>{item.short_decription}</td> */}
                    <td>{item.status}</td>
                    {/* <td>{item.price}</td> */}
                    {/* <td>{item.status}</td> */}

                    <td>
                      <button type="button" class="btn btn-primary ">
                        <i
                          className="fa fa-edit"
                          onClick={() =>
                            navigate('/update-staticpage', { state: { item } })
                          }
                        ></i>{' '}
                      </button>
                      &nbsp; &nbsp;
                      <button
                        type="button"
                        class="btn btn-danger "
                        onClick={() => deletestcpg(item._id)}
                      >
                        <i className="fa fa-trash"></i>{' '}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </>
    );
}
export default StaticPageList;