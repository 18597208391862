import axios from "axios";
import { useEffect, useState } from "react";
import { Image, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import NavBar from "../../../NavBar";
import { useNavigate } from "react-router-dom";

const Slider=()=>{
const navigate=useNavigate();


    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [allslider , setAllslider]=useState([]);
    const [name , setName]=useState("");
    const [file , setFile]=useState("");
    const [title , setTitle]=useState("");
    const [heading , setHeading]=useState("");
    const [status , setStatus]=useState("");
    const [slug , setSlug]=useState("");
    const token=localStorage.getItem("token");
    const [id , setId] =useState("");
    const maxFileSize=3000000;


    const getAllSlider=()=>{
        axios
        .get(`${BASE_URL}admin/getAllSlider`,{
            headers:{
                "Content-Type":"Application/json",
                "x-access-token":`${token}`,
                "version":"1.0.0"
            }
        }).then((response)=>{
            if(response.data.success==false)
            {
                // toast.error(response.data.message);
            }else{
                // toast.success(response.data.messsage);
                setAllslider(response.data.data);
                console.log("response",response.data.data);
            }
        }).catch((error)=>{
            console.log(error);
        })
    }

// const updateslide=(id)=>{
//     window.scrollTo(0, 0);
//     setId(id);
// }


    const AddSlider=()=>{
    // console.log(file[0].size);

if(file[0]==undefined||file[0]==""){
    toast.error("Image is required");
    return;
}

if(slug==""||slug.length==0){
    toast.error("slug is required");
    return;
}
   

 
        const formData= new FormData();
        formData.append("name",name);
        formData.append("status",status);
        formData.append("slug",slug);
        formData.append("title",title);
        formData.append("heading1",heading);
    
    
        formData.append("image",file[0]);
        // console.log(file);
        
        // let api="addSlider";
        // if(id)
        // {
        //  api="updateSlider";
        //  formData.append("id",id);
        // }

        // if(file[0].size>maxFileSize)
        // {
        //  toast.error("uploaded file is too large");
        //  return;
        // }
    
        axios
        .post(`${BASE_URL}admin/addSlider`,formData,{
            headers:{
                "Content-Type":"multipart/formdata",
                "version":"1.0.0",
                "x-access-token":`${token}`
            }
        }).then((response)=>{
            if(response.data.success==false)
            {
                toast.error(response.data.message);
            }
            else{
                toast.success(response.data.message);
                getAllSlider();
                setId("");
                setName("");
                setFile("");
                setSlug("");
                
            }
        })
    }

    useEffect(()=>{
        getAllSlider();
        // console.log(file);
       
    },[])


    // const updateSlider=()=>{


    //     axios
    //     .post(`${BASE_URL}admin/updateSlider/${id}`)
    // }

    const delSlider=(id)=>{
        if (window.confirm("Do you really want to delete this item?")) {
     
        } else {
         
            return ;
        }
        axios
        .delete(`${BASE_URL}admin/deleteSlider/${id}`,
        {
headers:{
    "Content-Type":"Application/json",
    "version":"1.0.0",
    "x-access-token":`${token}`
}
        }).then((response)=>{
            if(response.data.success==false)
            {
                toast.error(response.data.message);
            }
            else{
                toast.error(response.data.message);
                getAllSlider();
            }
        }).catch((error)=>{
            console.log(error);
        })
    }
    return(
    <>
     <NavBar />
<div className='container '>
    <div className='row d-flex justify-content-center align-items-center mt-5'>
        <div className='col-10 p-5' style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
      <div className="row">
        <div className="col-md-6">
            <label>Name</label>
            <input type="text" className="form-control"
            name="name"
            value={name}
            onChange={(e)=>setName(e.target.value)}
            />

        </div>
        <div className="col-md-6">
            <label>Select file</label>
            <input type="file" className="form-control" accept=".png , .jpeg , .jpg "
            name="file"
           
           onChange={(e)=>setFile(e.target.files)}
            />

        </div>
        <div className="col-md-6">
            <label>Title</label>
            <input type="text" className="form-control" 
            name="title"
           
           onChange={(e)=>setTitle(e.target.value)}
            />

        </div>
        <div className="col-md-6">
            <label>heading</label>
            <input type="text" className="form-control" 
            name="heading"
           
           onChange={(e)=>setHeading(e.target.value)}
            />

        </div>

        <div className="col-md-6">
            <label>Slug</label>
            <input type="text" className="form-control"
            name="slug"
            value={slug}
            onChange={(e)=>setSlug(e.target.value)}
            />

        </div>

        <div className="col-md-6">
            <label>Status</label>
            {/* <input type="file" className="form-control"/> */}
            <select className="form-control"
            name="status"
            value={status}
            onChange={(e)=>setStatus(e.target.value)}
            >
                <option>--select--</option>
                <option>Active</option>
                <option>Inactive</option>

            </select>

        </div>
        <button type="submit" className="btn btn-sm btn-primary mt-3 ms-3" style={{width:"60px"}} onClick={()=>AddSlider()}>Save</button>
      </div>

        </div>
    </div>


</div>
<div className="container mt-3">
<span style={{ fontSize: '20px', fontWeight: '600' }} className="p-2 mt-3">Slider Listing</span>

<div className="scrollit">
            <hr style={{ width: '165px', fontWeight: '700', color: 'grey' }}></hr>
            <Table striped bordered hover >
              <thead>
                <tr>
                  {/* <th>#</th> */}
                  <th>Image</th>
                  <th> Name</th>
                  <th> Title</th>
                  <th> heading</th>

                  <th>Status</th>
                  <th>Slug</th>
                  <th>Action</th>

                </tr>
              </thead>
              <tbody>

                {
                   allslider.map((item, index) => (
                    <tr key={index}>

                      <td><Image 
                      src=
                      {item.image}
                       style={{ width: '40px' }}></Image></td>
                      <td>
                        {item.name}
                        </td>
                        <td>
                        {item.title}
                        </td>
                        <td>
                        {item.heading1}
                        </td>
                      <td>
                        {item.status}
                        </td>
                        <td>
                        {item.slug}
                        </td>
                      <td>

                        <button type="button" class="btn btn-primary " onClick={()=>navigate('/update-slider', {state:{item}})} ><i className="fa fa-edit"></i> </button>
                        &nbsp; &nbsp;
                        <button type="button" class="btn btn-danger " onClick={()=>delSlider(item._id)}><i className='fa fa-trash'></i> </button>
                      </td>
                    </tr>

                  )) 
                 } 

            
              </tbody>
            </Table>
          </div>
</div>


    </>

        );
}
export default Slider;