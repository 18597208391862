import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Container, Row } from "react-bootstrap";
import NavBar from '../../../NavBar';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
const Size = () => {
  const [name, setName] = useState("");
  // const [image, setImage]=useState("");
  const [status, setStatus] = useState("Active");
  // const [heading , setHeading]=useState("");


  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [getSize, setGetSize] = useState([]);
  const [show, setShow] = useState(false);

  const [id, setId] = useState("");
  const navigate = useNavigate()



  const Addsize = () => {
    const token = localStorage.getItem("token");
     if(name==""||name.length==0){
     toast.error("Size is required.");
     return;
    }
    const obj = {
      name: name,
      status: status  
    }
    axios
      .post(`${BASE_URL}admin/addSize`, obj, {
        headers: {
          "x-access-token": `${token}`,
          "version": "1.0.0",
          "Content-Type": "Application/json"
        }
      }).then((response) => {
        if (response.data.success) {
          toast.success(response.data.message);
          setName("")
          getallSizes();
        } else {
          toast.error(response.data.message)
        }
      
      }).catch((error) => {
        console.log(error);
      })
  }


  const getallSizes = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getSize`, {
        headers: {
          "version": "1.0.0",
          "x-access-token": `${token}`,
          "Content-Type": "Application/json"
        }
      }).then((response) => {
        if (response.data.success == false) {
         
          if (response.data.error_code == 461) {
            navigate("/SignIn");
          }

        } else {
          
          console.log("response.data.data", response.data.data)
          setGetSize(response.data.data);

        }
      }).catch((error) => {
        console.log(error)
      })
  }


  const deleteSize = (id) => {
    if (window.confirm("Do you really want to delete this item?")) {

    } else {

      return;
    }
    const token = localStorage.getItem("token");
    axios
      .delete(`${BASE_URL}admin/deleteSize/${id}`, {
        headers: {
          "version": "1.0.0",
          "x-access-token": `${token}`,
          "Content-Type": "Application/json"
        }
      }).then((response) => {
        if (response.data.success == false) {
          toast.error(response.data.message);
          if (response.data.error_code == 461) {
            navigate("/SignIn");
          }
        } else {
          toast.error(response.data.message);
          getallSizes();

        }
      }).catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    getallSizes()
  }, [])

  return (
    <>

      <NavBar />
      <Container fluid style={{ height: '100vh', backgroundColor: '#f8f9fe;' }}>
        <Container>
          <Row className="mb-5">
            <Col md={1}>
            </Col>
            <Col >
              <div className="p-5 pt-3 mt-5 form" >
                <div className="dashboard mb-2 text-center" style={{ fontSize: '25px' }}>
                  Add Size 
                </div>

                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <label className='mb-1 mt-3'>Size:</label>
                  <Form.Control type="text" placeholder="Enter Size:" className='text'
                    name='name'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>

                <label className='mt-3mb-1'>Status:</label>
                <select
                  name='status'
                  className='text form-control '
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>

                <button type="button" class="btn btn-primary mt-3" onClick={() => Addsize()}> Save </button>

              </div>

            </Col>
            <Col md={1}>
            </Col>
          </Row>
          <span style={{ fontSize: '20px', fontWeight: '600' }} className="p-2">Size Listing</span>
          <div className="scrollit">

            <hr style={{ width: '165px', fontWeight: '700', color: 'grey' }}></hr>
            <Table striped bordered hover >
              <thead>
                <tr>
                  <th>S no.</th>
                  <th> Size</th>
                  {/* <th> Heading</th> */}

                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  getSize.map((item, index) => (
                    <tr key={index}>
                       <td className='tabletd'>{++index}</td>

                      <td>{item.name}</td>
                      {/* <td>{item.heading}</td> */}

                      <td>{item.status}</td>
                      <td>
                        <button type="button" class="btn btn-primary btn-sm "><i className="fa fa-edit" onClick={() => navigate("/update-size", { state: { item } })} ></i> </button>
                        &nbsp; &nbsp;
                        <button type="button" class="btn btn-danger btn-sm "><i className='fa fa-trash' onClick={() => deleteSize(item._id)}></i> </button>
                      </td>
                    </tr>
                  ))
                }

                {/* <tr>
                  <td>2</td>
                
                  <td>Thornton</td>
                  <td>InActive</td>
                  <td>
                    <button type="button" class="btn btn-primary "><i className="fa fa-edit"></i> </button>
                    &nbsp; &nbsp;
                    <button type="button" class="btn btn-danger "><i className='fa fa-trash'></i> </button>
                  </td>
                </tr> */}
                {/* <tr>
                  <td>3</td>
                
                  <td>gujh</td>
                  <td>Active</td>
                  <td>
                    <button type="button" class="btn btn-primary "><i className="fa fa-edit"></i> </button>
                    &nbsp; &nbsp;
                    <button type="button" class="btn btn-danger "><i className='fa fa-trash'></i> </button>
                  </td>
                </tr> */}
              </tbody>
            </Table>
          </div>
        </Container>
      </Container>


    </>
  );
}
export default Size;