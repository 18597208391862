import Col from 'react-bootstrap/Col';

import { Container, Row } from "react-bootstrap";
import NavBar from '../../../NavBar';

import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
const UpdateSize=()=>
{
  const {state}=useLocation();
  const [name, setName]=useState(state.item.name);
  // const [heading, setHeading]=useState(state.item.heading);

  // const [image, setImage]=useState("");
  const [status , setStatus]=useState(state.item.status);

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [id , setId]=useState(state.item._id);
  const navigate=useNavigate()

  const updhsize=()=>{
    const token=localStorage.getItem("token");
   const obj={
    name:name,
    status:status
   }
    axios
    .put(`${BASE_URL}admin/updateSize/${id}`,obj,{
      headers:{
        "x-access-token":`${token}`,
        "version":"1.0.0",
        "Content-Type":"Application/json"
      }
    }).then((response)=>{
      if(response.data.success==false){
        toast.error(response.data.message);
      }else{
       toast.success(response.data.message)}
       setName("")
       navigate("/add-size")
    
    }).catch((error)=>{
      console.log(error);
    })
  }

useEffect(()=>{
  console.log("state=================>",state);

},[])




// const updateHomeCategory=()=>{
//   const token=localStorage.getItem("token");
//   const obj={
//     name:name
//   }
//   axios
//   .put(`${BASE_URL}admin/updateHomeCategory/${id}`,obj,{
//     headers:{
//       "version":"1.0.0",
//       "x-access-token":`${token}`,
//       "Content-Type":"Application/json"
//     }
//   }).then((response)=>{
//     if(response.data.success==false){
//       toast.error(response.data.message);
//       if(response.data.error_code==461)
//       {
//           navigate("/SignIn");
//       }
//     }else{
//       toast.success(response.data.message);
//       getallhomecategory();
//     }
//   }).catch((error)=>{
//     console.log(error);
//   })
// }

// const saveChanges=()=>{
//   updateHomeCategory();
// }
    return(
        <>

 <NavBar/>
<Container fluid style={{ height: '100vh', backgroundColor: '#f8f9fe;' }}>
        <Container>
          <Row className="mb-5">
            <Col md={1}>
            </Col>
            <Col >
              <div className="p-5 pt-3 mt-5 form" >
                <div className="dashboard mb-2 text-center" style={{ fontSize: '25px' }}>
                 Update Size

                </div>
               
               
                <label className='mb-1 mt-3'> Size</label>
        <input type="text" placeholder="Enter Size" className='text form-control' 
        name='name'
        value={name}
        onChange={(e)=>setName(e.target.value)}
        />


<label className='mt-3 mb-1'>Status</label>
        <select
        name='status'
        className='text form-control '
        value={status}
        onChange={(e)=>setStatus(e.target.value)}
        >
          <option value="Active">Active</option>
          <option value="Inactive">Inactive</option>

        </select>
     
                <button type="button" class="btn btn-primary mt-5" onClick={()=>updhsize()}> Update </button>

              </div>

            </Col>
            <Col md={1}>
            </Col>
          </Row>

       
        </Container>
      </Container>

     
        </>
    );
}
export default UpdateSize;