
import Table from 'react-bootstrap/Table';
import NavBar from '../../NavBar';
import { Container } from 'react-bootstrap';
const List=()=>{
    return(
        <>
        <NavBar/>
       
       
            <Container>
            <div className='scrollit mt-4'>
         <Table striped bordered hover > 
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Mobile no.:</th>
          <th>Email Id:</th>
          <th>Address 1:</th>
          <th>Address 2:</th>
          <th>Type:</th>
          <th>Status:</th>
          <th>Date:</th>
          <th>Amount:</th>
          
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Mark</td>
          <td>Otto</td>
          <td>@mdo</td>
          <td>Otto</td>
          <td>@mdo</td>
          <td>Otto</td>
          <td>@mdo</td>
          <td>Otto</td>
          <td>@mdo</td>
        </tr>
        <tr>
        <td>1</td>
          <td>Mark</td>
          <td>Otto</td>
          <td>@mdo</td>
          <td>Otto</td>
          <td>@mdo</td>
          <td>Otto</td>
          <td>@mdo</td>
          <td>Otto</td>
          <td>@mdo</td>
        </tr>
        <tr>
        <td>1</td>
          <td>Mark</td>
          <td>Otto</td>
          <td>@mdo</td>
          <td>Otto</td>
          <td>@mdo</td>
          <td>Otto</td>
          <td>@mdo</td>
          <td>Otto</td>
          <td>@mdo</td>
        </tr>
      </tbody>
    </Table>
    </div>
    </Container>
    
    
        </>
    );
}
export default List;