import React, { useEffect, useState } from 'react';
// import './cart.css';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import NavBar from '../../NavBar';
import imag1 from "./productimages/1.png"
import imag2 from "./productimages/2.png"
import imag3 from "./productimages/3.png"
import imag4 from "./productimages/4.png"
import imag5 from "./productimages/5.png"
import imag6 from "./productimages/6.png"
import imag7 from "./productimages/7.png"
import imag8 from "./productimages/8.png"
import imag9 from "./productimages/9.png"
import imag10 from "./productimages/10.png"






function FrameInfo() {
  const navigate=useNavigate();
    const { state } = useLocation();

    const initialValues = {
      baby_name: state.item.framedata[0].baby_name,
      baby_tob: state.item.framedata[0].baby_tob,
      baby_weight: state.item.framedata[0].baby_weight,
      hospital_name: state.item.framedata[0].hospital_name,
      baby_gender: state.item.framedata[0].baby_gender,
      blood_group: state.item.framedata[0].blood_group,
      mother_name: state.item.framedata[0].mother_name,
      father_name: state.item.framedata[0].father_name,
      // baby_image:''
    };
  const [values, setValues] = useState(initialValues);

    // setValues(state.item.framedata);

  const [baby_image, setBabyImage] = useState(state.item.framedata[0].baby_image);
  const [mother_image, setMotherImage] = useState(state.item.framedata[0].mother_image);
  const [father_image, setFatherImage] = useState(
    state.item.framedata[0].father_image
  );
  const [parents_image, setParentsImage] = useState(
    state.item.framedata[0].parents_image
  );
  const BASE_URL = process.env.REACT_APP_BASE_URL;
//   const [frameinfo, setFrameInfo] = useState([]);



  return (
    <>
      {/* <Layout>
       */}

      {/* <TopBar /> */}
      {/* <Header/> */}
      {/* <Navmenu /> */}
          <NavBar    />
      <section class="cart-info-ection">
        <img
          src="https://elzero.org/images/challenges/person-blue-shirt.png"
          alt="Person Pointing"
          class="cart-info-img"
        />
        <div class="info-box">
          <br />
          <div>
            <h1 class="text-white mt-3 text-center">Frame Info </h1>
            <div class="w-100  d-flex justify-content-center">
              <div className="row bg-white shadow-sm p-5 frameInfoDiv">
                <div className="col-md-6">
                  <label className="py-2 "> Baby Name</label>
                  <input
                    type="text"
                    name="baby_name"
                    class="form-control p-3 "
                    placeholder="Baby Name"
                    value={values?.baby_name}
                    // onChange={(e) => onInputChanged(e)}
                  />
                </div>
                <div className="col-md-6">
                  <label className="py-2 "> Baby Date Of Birth</label>
                  <input
                    type="date"
                    name="baby_dob"
                    class="form-control p-3 "
                    placeholder="Enter Baby Date Of Birth"
                    value={moment(values?.baby_dob).format('YYYY-MM-DD')}
                    // onChange={(e) => onInputChanged(e)}
                  />
                </div>
                <div className="col-md-6">
                  <label className="py-2"> Baby Time Of Birth</label>
                  <input
                    type="time"
                    name="baby_tob"
                    class="form-control p-3 "
                    placeholder="Enter Baby Time Of Birth"
                    value={values?.baby_tob}
                    // onChange={(e) => onInputChanged(e)}
                  />
                </div>

                <div className="col-md-6">
                  <label className="py-2 "> Baby Weight</label>
                  <input
                    type="text"
                    name="baby_weight"
                    class="form-control p-3 "
                    placeholder="Enter Baby Weight"
                    value={values?.baby_weight}
                    // onChange={(e) => onInputChanged(e)}
                  />
                </div>

                <div className="col-md-6">
                  <label className="py-2 "> Hospital Name</label>
                  <input
                    type="text"
                    className="form-control p-3 "
                    name="hospital_name"
                    placeholder="Enter Hospital Name"
                    value={values?.hospital_name}
                    // onChange={(e) => onInputChanged(e)}
                  />
                </div>

                <div className="col-md-6">
                  <label className="py-2 ">Choose Baby Boy Or Girl</label>
                  <select
                    aria-label="Floating label select example"
                    name="baby_gender"
                    className="form-control p-3 "
                    // onChange={(e) => onInputChanged(e)}
                    value={values?.baby_gender}
                  >
                    <option>------</option>
                    <option value="girl">girl</option>
                    <option value="boy">boy</option>
                  </select>
                </div>

                <div className="col-md-6">
                  <label className="py-2 ">Baby Blood Group</label>
                  <input
                    type="text"
                    name="blood_group"
                    className="form-control p-3 "
                    placeholder="Enter Hospital Name"
                    value={values?.blood_group}
                    // onChange={(e) => onInputChanged(e)}
                  />
                </div>
                <div className="col-md-6">
                  <label className="py-2 ">Mother Name</label>
                  <input
                    type="text"
                    className="form-control p-3 "
                    placeholder="Enter Mother Name"
                    name="mother_name"
                    value={values?.mother_name}
                    // onChange={(e) => onInputChanged(e)}
                  />
                </div>
                <div className="col-md-6">
                  <label className="py-2 ">Father Name</label>
                  <input
                    type="text"
                    className="form-control p-3 "
                    name="father_name"
                    placeholder="Enter Father Name"
                    value={values?.father_name}
                    // onChange={(e) => onInputChanged(e)}
                  />
                </div>
                <div className="col-md-6">
                  <label className="py-2 ">Baby Image</label>
                  <input
                    type="file"
                    placeholder="Upload Baby Image:"
                    name="baby_image"
                    className="form-control p-3 "
                    // onChange={(e) => setBabyImage(e.target.files)}
                  />
                  <br />
                  <img
                    src={baby_image?baby_image:""}
                    height="auto"
                    style={{ width: '100px' }}
                    alt=''
                  />
                </div>
                <div className="col-md-6">
                  <label className="py-2 ">Mother Image</label>
                  <input
                    type="file"
                    placeholder="Upload Baby Image:"
                    name="mother_image"
                    className="form-control p-3 "
                    // onChange={(e) => setMotherImage(e.target.files)}
                  />
                  <br />
                  <img
                    src={mother_image?mother_image:""}
                    height="auto"
                    style={{ width: '100px' }}
                    alt=''
                  />
                </div>
                <div className="col-md-6">
                  <label className="py-2 ">Father Image</label>
                  <input
                    type="file"
                    placeholder="Upload Father Image:"
                    name="father_image"
                    className="form-control p-3 "
                    // onChange={(e) => setFatherImage(e.target.files)}
                  />
                  <br />
                  <img
                    src={father_image?father_image:""}
                    height="auto"
                    style={{ width: '100px' }}
                    alt=''
                  />
                </div>
                <div className="col-md-6">
                  <label className="py-2 ">Parents Image</label>
                  <input
                    type="file"
                    placeholder="Upload Parents Image:"
                    name="parents_image"
                    className="form-control p-3 "
                    // onChange={(e) => setParentsImage(e.target.files)}
                  />
                  <br />
                  <img
                    src={parents_image?parents_image:""}
                    height="auto"
                    style={{ width: '100px' }}
                    alt=''
                  />
                </div>

                {/* <div className="col-md-12">
                  <button
                    className="btn buttonBlue p-3 w-100 m-3 "
                    // onClick={() => updateFrameInfo()}
                  >
                    Update
                  </button>
                </div> */}
                {/* </div> */}


                <div>
                  <div className='row'>
                    <div className='col-2 mt-3'>
                      {/* <img src='./Product-image/1.png' className='img-fluid' alt='image'/> */}
                      {/* <img src={imag1} height="auto"  alt="Product Image"/> */}
                      <img
                    src={imag1}
                    height="auto"
                    className='infoDetailimgHover'
                    style={{ width: '100px' }}
                    onClick={()=>navigate("/first-frame-A4-design",{state:{state}})}
                    alt=''
                  />
                      </div>
                    <div className='col-2  mt-3'>
                    <img
                    src={imag2}
                    height="auto"
                    className='infoDetailimgHover'
                    style={{ width: '100px' }}
                    onClick={()=>navigate("/first-frame-A3-design",{state:{state}})}

                    
                    alt=''
                  />
                    </div>
                    <div className='col-2  mt-3'>
                    <img
                    src={imag3}
                    height="auto"
                    className='infoDetailimgHover'
                    style={{ width: '100px' }}
                    onClick={()=>navigate("/first-frame-A5-design",{state:{state}})}

                    
                    alt=''
                  />
                    </div>
                    <div className='col-2 mt-3'>
                    <img
                    src={imag4}
                    height="auto"
                    className='infoDetailimgHover'
                    style={{ width: '100px' }}
                    onClick={()=>navigate("/second-frame-A3-design",{state:{state}})}
                    
                    alt=''
                  />
                    </div>
                    <div className='col-2  mt-3'>
                    <img
                    src={imag5}
                    height="auto"
                    className='infoDetailimgHover'
                    onClick={()=>navigate("/second-frame-A4-design",{state:{state}})}

                    
                    style={{ width: '100px' }}
                    alt=''
                  />
                    </div>

                    <div className='col-2  mt-3'>
                    <img
                    src={imag6}
                    height="auto"
                    className='infoDetailimgHover'
                    style={{ width: '100px' }}
                    onClick={()=>navigate("/second-frame-A5-design",{state:{state}})}
                    
                    alt=''
                  />
                    </div>

                    <div className='col-2  mt-3'>
                    <img
                    src={imag7}
                    height="auto"
                    className='infoDetailimgHover'
                    style={{ width: '100px' }}
                    onClick={()=>navigate("/third-frame-A3-design",{state:{state}})}
                    
                    alt=''
                  />
                    </div>
                    <div className='col-2  mt-3'>
                    <img
                    src={imag7}
                    height="auto"
                    className='infoDetailimgHover'
                    style={{ width: '100px' }}
                    onClick={()=>navigate("/third-frame-A4-design",{state:{state}})}
                    
                    alt=''
                  />
                    </div>

                    <div className='col-2  mt-3'>
                    <img
                    src={imag7}
                    height="auto"
                    className='infoDetailimgHover'
                    style={{ width: '100px' }}
                    onClick={()=>navigate("/third-frame-A5-design",{state:{state}})}
                    
                    alt=''
                  />
                    </div>
                    <div className='col-2  mt-3'>
                    <img
                    src={imag8}
                    height="auto"
                    className='infoDetailimgHover'
                    style={{ width: '100px' }}
                    onClick={()=>navigate("/fourth-frame-A3-design",{state:{state}})}
                    
                    alt=''
                  />
                    </div>
                    <div className='col-2  mt-3'>
                    <img
                    src={imag9}
                    height="auto"
                    className='infoDetailimgHover'
                    style={{ width: '100px' }}
                    onClick={()=>navigate("/fourth-frame-A4-design",{state:{state}})}
                    
                    alt=''
                  />
                    </div>
                    <div className='col-2  mt-3'>
                    <img
                    src={imag10}
                    height="auto"
                    className='infoDetailimgHover'
                    style={{ width: '100px' }}
                    onClick={()=>navigate("/fourth-frame-A5-design",{state:{state}})}
                    alt=''
                  />
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <Footer/> */}
      {/* </Layout> */}
    </>
  );
}

export default FrameInfo;
