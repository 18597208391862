// import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Container, Row } from "react-bootstrap";
import NavBar from "../../../NavBar";
import Table from "react-bootstrap/Table";
import Image from "react-bootstrap/Image";
import Nav from "react-bootstrap/Nav";
import axios from "axios";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useLocation, useNavigate } from "react-router-dom";

const Updatesubcategory = () => {
  const { state } = useLocation();

  const navigate = useNavigate();

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [getallsubCategory, setGetAllSubcategory] = useState([]);
  const [getallcategory, setGetAllCategory] = useState([]);
  const [name, setName] = useState(state.item.name);
  const [categoryid, setCategoryId] = useState(
    state.item.category_details[0]?._id
  );
  const [id, setId] = useState(state.item._id);
  const [status, setStatus] = useState(state.item.status);
  // const [show, setShow] = useState(false);

  useEffect(() => {
    getAllSubCategory();
    getAllCat();
    console.log("state==================>", state);
  }, []);
  const getAllSubCategory = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getAllSubCategory`, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          Version: "1.0.0",
        },
      })
      .then(function (response) {
        // console.log(response);
        if (response.data.success == false) {
          // toast.error(response.data.message);
        } else {
          // toast.success(response.data.message)
          setGetAllSubcategory(response.data.data);

          console.log("setGetAllSubcategory", response.data.data);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const getAllCat = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getAllCategory`, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          // toast.error(response.data.message);
        } else {
          // toast.success(response.data.message);
          setGetAllCategory(response.data.data);
          console.log("get all category", response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const UpdSubCategory= (id) => {
  //   setId(id);
  // }

  // const update=(id)=>{
  //   setShow(true);
  //   setId(id);
  //  window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

  // }

  const UpdatesubCategory = () => {
    const obj = {
      name: name,
      CategoryId: categoryid,
      status: status,
      id: id,
    };

    const token = localStorage.getItem("token");
    axios
      .put(`${BASE_URL}admin/updateSubCategory`, obj, {
        headers: {
          "x-access-token": `${token}`,
          " Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          toast.error(response.data.message);
        } else {
          toast.success(response.data.message);
          setName("");
          getAllCat();

          getAllSubCategory();
          navigate("/SubCategory");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <NavBar />
      <Container fluid style={{ height: "100vh", backgroundColor: "#f8f9fe;" }}>
        <Container>
          <Row className="mb-5">
            <Col md={1}></Col>
            <Col>
              <div className="p-5 pt-3 mt-5 form">
                <div
                  className="dashboard mb-2 text-center"
                  style={{ fontSize: "25px" }}
                >
                  Update Sub Categories
                </div>

                <label className="pb-1 pt-3 ">Category:</label>

                <Form.Select
                  aria-label="Default select example"
                  className="select text"
                  style={{
                    backgroundColor: "#f8f9fe",
                    borderRadius: "5px",
                    padding: "10px",
                  }}
                  name="category"
                  value={categoryid}
                  onChange={(e) => setCategoryId(e.target.value)}
                >
                  <option> --select Category --</option>
                  {getallcategory.map((item, index) => (
                    <option value={item._id} key={index}>
                      {item.name}
                    </option>
                  ))}

                  {/* <option value="2">Two</option>
      <option value="3">Three</option> */}
                </Form.Select>
                <label className="pb-1 pt-3 ">SubCategory name:</label>

                <input
                  type="text"
                  className="form-control text"
                  placeholder="Enter Subcategory Name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <label className="pb-1 pt-3 ">Status:</label>

                <select
                  name="status"
                  className="form-control text"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>

                {/* <input type="file" className="form-control file" ></input> */}
                <button
                  type="button"
                  class="btn btn-primary mt-3"
                  onClick={() => UpdatesubCategory()}
                >
                  {" "}
                  Update{" "}
                </button>
              </div>
            </Col>
            <Col md={1}></Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};
export default Updatesubcategory;
