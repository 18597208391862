
import AllOrder from "./Order";

const ReturnOrders=()=>{

    return(
        <>
 <AllOrder  status="Return"/>
     </>
    );
} 
export default ReturnOrders;