import axios from "axios";
import React, {react, useEffect, useState} from "react";
import NavBar from "../../NavBar";
import moment from 'moment';
import { useLocation, useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
// import React from "react";
const OrderDetails = () => {
    const state = useLocation();
    const item = state.state;
    const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

    // const [couponId , setCoupon_codeId]=useState(item.coupon_code_id);
    // const [coupon , setCoupon_code]=useState("");

    console.log("item==>",item);

    const renderHTML = (rawHTML) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  
    return (
      <>
        <NavBar />
        <div className="container ">
          <div className="row d-flex justify-content-center align-items-center mt-5">
            <div
              className="col-10 p-5"
              style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}
            >
              <div
                className="dashboard mb-2 text-center"
                style={{ fontSize: '25px' }}
              >
                Order details
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="p-2">OrderId:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item?.user_Order_ID}
                    disabled
                  />
                </div>

                <div className="col-md-6">
                  <label className="p-2">User Name:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item?.name}
                    disabled
                  />
                </div>

                <div className="col-md-6">
                  <label className="p-2">Email:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item?.email}
                    disabled
                  />
                </div>
                <div className="col-md-6">
                  <label className="p-2">Mobile number:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item?.mobile_no}
                    disabled
                  />
                </div>
                <div className="col-md-6">
                  <label className="p-2">Address 1:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item?.address}
                    disabled
                  />
                </div>
                <div className="col-md-6">
                  <label className="p-2">Address 2:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item?.address2}
                    disabled
                  />
                </div>

                {/* <div className="col-md-6">
                                <label className='p-2'>Country:</label>
                                <input type="text" className="form-control"
                                    name="name"
                                    value={item.country}
                                    disabled

                                />

                            </div> */}

                <div className="col-md-6">
                  <label className="p-2">State:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item?.state}
                    disabled
                  />
                </div>

                <div className="col-md-6">
                  <label className="p-2">City:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item?.city}
                    disabled
                  />
                </div>

                <div className="col-md-6">
                  <label className="p-2">Pin code:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item?.zip_code}
                    disabled
                  />
                </div>
                <div className="col-md-6">
                  <label className="p-2">Coupon Code :</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item?.coupon_details[0]?.coupon_code}
                    disabled
                  />
                </div>

                <div className="col-md-6">
                  <label className="p-2">Amount :</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item?.amount}
                    disabled
                  />
                </div>
                <div className="col-md-6">
                  <label className="p-2">Discount Amount :</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item?.discount_amount}
                    disabled
                  />
                </div>

                <div className="col-md-6">
                  <label className="p-2">Total Amount :</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item?.total_amount}
                    disabled
                  />
                </div>

                <div className="col-md-6">
                  <label className="p-2">razorpay payment id :</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item?.razorpay_payment_id}
                  />
                </div>

                <div className="col-md-6">
                  <label className="p-2">shipping charge:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item?.shipping_charge}
                    disabled
                  />
                </div>

                <div className="col-md-6">
                  <label className="p-2">Delivery Type</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item?.delivery_type}
                    disabled
                  />
                </div>

                <div className="col-md-6">
                  <label className="p-2">Payment status</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item?.payment_status}
                    disabled
                  />
                </div>

                <div className="col-md-6">
                  <label className="p-2">status</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item?.status}
                    disabled
                  />
                </div>
                <div className="col-md-6">
                  <label className="p-2">Order date:</label>
                  <input
                    type="date"
                    className="form-control"
                    name="name"
                    value={moment(item?.createdAt).format('YYYY-MM-DD')}
                    disabled
                  />
                </div>
              </div>

              {/* cart start  */}
              <div className="mt-5">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      {/* <th>#</th> */}
                      <th>Product Image:</th>
                      <th>Product name:</th>

                      <th> Price:</th>
                      {/* <th> Color:</th> */}
                      <th> Size:</th>
                      <th> Quantity:</th>
                      {/* <th> heading</th> */}
                      {/* <th> product description</th> */}
                      {/* <th>Status</th> */}
                      <th>Frame Info</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.cart.length > 0 &&
                      item.cart.map((item, index) => (
                        <tr key={index}>
                          {/* <p>{item}</p> */}
                          <td>
                            <img
                              src={`${item.Product_details[0].image[0]}`}
                              style={{ width: '40px', height: '50px' }}
                            />
                          </td>
                          <td>{item.Product_details[0].name}</td>
                          <td>
                            {item.price
                              ? item.price
                              : item.Product_details[0].price}
                          </td>
                          {/* <td>{item.color}</td> */}
                          <td>{item.size}</td>
                          <td>{item.quantity}</td>
                          <td>
                            <button
                              className="btn btn-primary"
                              onClick={
                                () =>  navigate('/frame-info', { state: {item} })
                              }
                            >
                              <i className="fa fa-eye"></i>
                            </button>
                          </td>

                          {/* <td>{renderHTML(item.Product_details[0].short_decription)}</td> */}

                          {/* <td>{item.description}</td> */}

                          {/* <td>{item.quantity}</td> */}
                          {/* <td>

                                                  <button onClick={()=>console.log("image",item.Product_details[0].image[0])}>click</button>
                                                  
                                                </td> */}
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
              {/* cart end  */}
            </div>
          </div>
        </div>
      </>
    );
}
export default OrderDetails;
