
// import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Container, Row } from "react-bootstrap";
import NavBar from '../../../NavBar';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';


const SubCategory = () => {
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [getallsubCategory, setGetAllSubcategory] = useState([]);
  const [getallcategory, setGetAllCategory] = useState([]);
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [id, setId] = useState("");
  const [status, setStatus] = useState("Active");
  const [categoryid, setCategoryId] = useState('');
  // const [show, setShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState();

  useEffect(() => {
    getAllSubCategory();
    getAllCat();
  }, [])
  const getAllSubCategory = () => {

    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getSubCategory/${currentPage}`, {

        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          "Version": "1.0.0"
        },

      })
      .then(function (response) {

        // console.log(response);
        if (response.data.success == false) {
          // toast.error(response.data.message);
        }
        else {
          // toast.success(response.data.message)
          setGetAllSubcategory(response.data.data);
          setPages(response.data.remainingCount);

          console.log("setGetAllSubcategory", response.data.data);
        }
      })
      .catch((error) => {
        toast.error(error);
      })
  }


  const getAllCat = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getAllCategory`, {
        headers: {
          "x-access-token": `${token}`,
          "version": "1.0.0",
          "Content-Type": "multipart/form-data"
        }
      }).then((response) => {
        if (response.data.success == false) {
          // toast.error(response.data.message);
        } else {
          // toast.success(response.data.message);
          setGetAllCategory(response.data.data);
          console.log("get all category", response.data.data)
        }
      }).catch((error) => {
        console.log(error);
      })
  }

  // const UpdSubCategory= (id) => {
  //   setId(id);
  // }

  const save = () => {
    if (!categoryid) {
      toast.error("Please select category.");
      return;
    }
    if (name.length < 0 || name == "") {
      toast.error("Subcategory name is required");
      return;
    }

    const obj = {
      name: name,
      // category:category
      CategoryId: categoryid,
      status: status

    }
    const token = localStorage.getItem("token");
    axios
      .post(`${BASE_URL}admin/addSubCategory`, obj, {
        headers: {
          "x-access-token": `${token}`,
          " Content-Type": "Application/json",
          "version": "1.0.0"
        }
      }).then((response) => {
        if (response.data.success == false) {
          toast.error(response.data.message);
        }
        else {
          toast.success(response.data.message);
          setName("");
          getAllCat();

          getAllSubCategory();
        }
      })
  }
  // const update=(id)=>{
  //   setShow(true);
  //   setId(id);
  //  window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

  // }

  // const UpdatesubCategory = () => {


  //   const obj = {
  //     name: name,
  //     category: category,
  //     _id: id,
  //   }

  //   const token = localStorage.getItem("token");
  //   axios
  //     .put(`${BASE_URL}admin/updateSubCategory/${id}`, obj, {
  //       headers: {
  //         "x-access-token": `${token}`,
  //         " Content-Type": "Application/json",
  //         "version": "1.0.0"
  //       }
  //     }).then((response) => {
  //       if (response.data.success == false) {
  //         toast.error(response.data.message);
  //       }
  //       else {
  //         toast.success(response.data.message);
  //         setName("");
  //         getAllCat();

  //         getAllSubCategory();
  //       }
  //     }).catch((error) => {
  //       console.log(error);
  //     })


  // }

  const deleteSubcategory = (id) => {
    if (window.confirm("Do you really want to delete this item?")) {

    } else {

      return;
    }
    const token = localStorage.getItem("token");
    axios
      .delete(`${BASE_URL}admin/deleteSubCategory/${id}`, {
        headers: {
          "version": "1.0.0",
          "x-access-token": `${token}`,
          "Content-Type": "Application/json"
        }
      }).then((response) => {
        if (response.data.success == false) {
          toast.error(response.data.message);
        } else {
          toast.error(response.data.message);
          getAllSubCategory();
        }
      }).catch((error) => {
        console.log(error);
      })
  }


  const handlePageChange = (page) => {
    setCurrentPage(page);
    getAllCat(page);

  }
  useEffect(() => {
    getAllSubCategory();
    getAllCat();
  }, [currentPage])
  return (
    <>
      <NavBar />
      <Container fluid style={{ height: '100vh', backgroundColor: '#f8f9fe;' }}>
        <Container>
          <Row className="mb-5">
            <Col md={1}>
            </Col>
            <Col >
              <div className="p-5 mt-5 form" >
                <div className="dashboard mb-2 text-center" style={{ fontSize: '25px' }}>
                  Add Sub Categories

                </div>

                <label className="pb-1 pt-3 ">Category:</label>

                <select
                  name="category"
                  className="text form-control "
                  value={categoryid}
                  onChange={(e) => setCategoryId(e.target.value)}
                  style={{ backgroundColor: '#f8f9fe', borderRadius: '5px', padding: '10px' }}
                >
                  <option> --select Category --</option>
                  {
                    getallcategory.map((item, index) => (
                      <option value={item._id} key={index}
                      >{item.name}</option>
                    ))
                  }
                </select>
                <label className="pb-1 pt-3 ">SubCategory name:</label>

                <input type='text' className='text form-control ' placeholder='Name'
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <label className="pb-1 pt-3 "> Status:</label>
                <select
                  name="status"
                  className="text form-control "
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
                <button type="button" class="btn btn-primary mt-3" onClick={() => save()}> Save </button>
              </div>
            </Col>
            <Col md={1}>
            </Col>
          </Row>
          <span style={{ fontSize: '20px', fontWeight: '600' }} className="">Subcategory Listing</span>
          <div className="scrollit">
            <hr style={{ width: '165px', fontWeight: '700', color: 'grey', margin: "10px" }}></hr>
            <Table striped bordered hover >
              <thead>
                <tr>
                <th>S no.</th>

                  <th>Category</th>
                  <th> Subcategory</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  getallsubCategory.map((item, index) => (
                    <tr key={index}>
                      <td className='tabletd'>{++index}</td>

                      <td>{item.category_details[0]?.name}</td>
                      <td>{item.name}</td>
                      <td>{item.status}</td>
                      <td>
                        <button type="button" class="btn btn-primary btn-sm "
                          onClick={() => navigate("/Update-subcategory", { state: { item } })}
                        ><i className="fa fa-edit"></i> </button>
                        &nbsp; &nbsp;
                        <button type="button" class="btn btn-danger btn-sm "><i className='fa fa-trash' onClick={() => deleteSubcategory(item._id)}></i> </button>
                      </td>
                    </tr>

                  )
                  )
                }


              </tbody>
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class={`page-item ${currentPage === 1 ? "disabled" : ""} `}>
                    <a class="page-link" href="#" aria-label="Previous" onClick={() => handlePageChange(currentPage - 1)}>
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                

                  {Array.from({ length: pages }, (_, i) => i + 1).map((page) => (
                    <>
                      <li key={page} className={`page-item ${page === currentPage ? "active" : ""}`}>
                        <button className="page-link" onClick={() => handlePageChange(page)}>
                          {page}
                        </button>
                      </li>
                    </>
                  ))}
                  <li class={`page-item ${currentPage === pages ? "disabled" : ""}`}>
                    <a class="page-link" href="#" aria-label="Next" onClick={() => handlePageChange(currentPage + 1)}>
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </Table>
          </div>
        </Container>
      </Container>





    </>
  );
}
export default SubCategory;