import Table from "react-bootstrap/Table";
import NavBar from "../../../NavBar";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const AddProduct = () => {
  const [sizes, setSizes] = useState([]);
  const [size, setSize] = useState("");
  const [vmrp, setVmrp] = useState();
  const [vprice, setVprice] = useState();
  const [productvarient, setProductvarient] = useState([]);
  const [verityDef, setVerityDef] = useState(false);
  const [name, setName] = useState("");
  const [mrp, setMrp] = useState();
  const [quantity, setquantity] = useState();
  const [price, setPrice] = useState();
  const [shortdes, setShortdes] = useState("");
  const [longdes, setLongdes] = useState("");
  const [file, setFile] = useState([]);
  const [shippinginfo, setShippingInfo] = useState("");
  const [refundpolicy, setRefundPolicy] = useState("");
  const [newarrivals, setNewarrivals] = useState(false);
  const [momImage, setMomImage] = useState(false);
  const [dadImage, setDadImage] = useState(false);
  const [parentsImage, setParentsImage] = useState(false);
  const [numberOfBabyImage, setNumberOfBabyImage] = useState();
  const [numberOfParentsImage, setNumberOfParentsImage] = useState();
  const [bestsellcheck, setBestsellcheck] = useState(false);
  const [status, setStatus] = useState("");
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [category, setCategory] = useState([]);
  const [allsubcategory, setAllSubCategory] = useState([]);
  const [hcategory, setHcategory] = useState("");
  const [ctgryId, setCtgryId] = useState("");
  const [scategory, setScategory] = useState("");
  const getAllCat = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getAllCategory`, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setCategory(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSubCategory = () => {
    const token = localStorage.getItem("token");

    axios
      .post(
        `${BASE_URL}admin/getSubCategoryByCategoryId`,
        { id: ctgryId },
        {
          headers: {
            "x-access-token": `${token}`,
            "Content-Type": "Application/json",
            Version: "1.0.0",
          },
        }
      )
      .then(function (response) {
        if (response.data.success == false) {
          setAllSubCategory([]);
        } else {
          setAllSubCategory(response.data.data);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const AddProduct = () => {
    if (name == "" || name.length == 0) {
      toast.error("Product name is required");
      return;
    }
    if (isNaN(mrp) || mrp == undefined) {
      toast.error("Please enter valid mrp. ");
      return;
    }
    if (isNaN(price) || mrp == undefined) {
      toast.error("Please enter valid price. ");
      return;
    }
    if (isNaN(quantity) || mrp == undefined) {
      toast.error("Please enter valid quantity");
      return;
    }
    if (!numberOfBabyImage || isNaN(numberOfBabyImage)) {
      toast.error("Please enter valid number of baby image");
      return;
    }
    if (!numberOfParentsImage || isNaN(numberOfParentsImage)) {
      toast.error("Please enter valid number of parent image");
      return;
    }
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("shipping_info", shippinginfo);
    formData.append("refund_policy", refundpolicy);
    formData.append("name", name);
    formData.append("mrp", mrp);
    formData.append("price", price);
    formData.append("quantity", quantity);
    formData.append("productvarient", JSON.stringify(productvarient));
    formData.append("short_decription", shortdes);
    formData.append("long_decription", longdes);
    for (let i = 0; i < file.length; i++) {
      formData.append("image", file[i]);
    }
    formData.append("status", status);
    formData.append("CategoryId", ctgryId);
    formData.append("SubCategoryId", scategory);
    formData.append("HomeCategoryId", hcategory);
    formData.append("bestseller", bestsellcheck);
    formData.append("newarrivals", newarrivals);
    formData.append("number_of_baby_image", numberOfBabyImage);
    formData.append("number_of_parent_image", numberOfParentsImage);
    formData.append("momImage", momImage);
    formData.append("dadImage", dadImage);
    formData.append("parentImage", parentsImage);

    axios
      .post(`${BASE_URL}admin/addProduct`, formData, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          toast.error(response.data.message);
        } else {
          const productVariantsWithProductId = productvarient.map(
            (variant) => ({
              ...variant,
              product_id: response.data.data._id,
            })
          );
          AddProductVarients(productVariantsWithProductId);

          toast.success(response.data.message);
          navigate("/ProductListing");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getAllCat();
  }, []);

  useEffect(() => {
    if (ctgryId != "") {
      getSubCategory();
    }
  }, [ctgryId]);

  const Fntosetsubc = (value, getSubCategory) => {
    setCtgryId(value);
  };
  const getSizes = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getSize`, {
        headers: {
          "Content-Type": "Application/json",
          "x-access-token": `${token}`,
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success === false) {
          console.log(response.data.message);
        } else {
          setSizes(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getSizes();
  }, []);

  const addproductV = async () => {
    if (size == "" || size == undefined) {
      toast.error(" Size is required.");
      return;
    }

    if (isNaN(vmrp) || vmrp == undefined || vmrp == null) {
      toast.error(" please enter valid varient MRP.");
      return;
    }

    if (isNaN(vprice) || vprice == undefined || vprice == null) {
      toast.error(" please enter valid varient price.");
      return;
    }
    let obj = {
      size: size,
      price: vprice,
      mrp: vmrp,
      set_default: verityDef,
    };

    productvarient.push(obj);
    setSize("");
    setVprice("");
    setVmrp("");
    setVerityDef(false);
  };

  const AddProductVarients = (items) => {
    const token = localStorage.getItem("token");
    console.log("items", items);
    axios
      .post(`${BASE_URL}admin/addProductVarient`, items, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success === false) {
          console.log(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <>
      <NavBar />
      <div className="container ">
        <div className="row d-flex justify-content-center align-items-center mt-5">
          <div
            className="col-10 p-5"
            style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
          >
            <div className="dashboard mb-2" style={{ fontSize: "25px" }}>
              Add Product
            </div>
            <div className="row">
              <div className="col-md-6">
                <label className="p-2">Category</label>
                <select
                  name="category"
                  className="form-control"
                  value={ctgryId}
                  onChange={(e) => Fntosetsubc(e.target.value, getSubCategory)}
                >
                  <option value="">--select--</option>
                  {category.map((item, index) => (
                    <option value={item._id} key={index}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6">
                <label className="p-2">Subcategory</label>
                <select
                  name="subcatgeory"
                  className="form-control"
                  value={scategory}
                  onChange={(e) => setScategory(e.target.value)}
                >
                  <option value="">--select--</option>
                  {allsubcategory.map((item, index) => (
                    <option value={item._id} key={index}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6">
                <label className="p-2">Product name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label className="p-2">MRP</label>
                <input
                  type="text"
                  className="form-control"
                  name="mrp"
                  value={mrp}
                  onChange={(e) => setMrp(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label className="p-2">Price</label>
                <input
                  type="text"
                  className="form-control"
                  name="price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label className="p-2">Quantity</label>
                <input
                  type="text"
                  className="form-control"
                  name="quantity"
                  value={quantity}
                  onChange={(e) => setquantity(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label className="p-2">Image</label>
                <input
                  type="file"
                  className="form-control"
                  multiple
                  name="file"
                  // value={file}
                  onChange={(e) => setFile(e.target.files)}
                />
              </div>
              <div className="col-md-6">
                <label className="p-2">Short description</label>

                <CKEditor
                  editor={ClassicEditor}
                  data={shortdes}
                  onReady={(editor) => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setShortdes(data);
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                />
              </div>
              <div className="col-md-6">
                <label className="p-2">Long description</label>

                <CKEditor
                  editor={ClassicEditor}
                  data={longdes}
                  onReady={(editor) => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setLongdes(data);
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                />
              </div>

              <div className="col-md-6">
                <label className="p-2">Shipping Info </label>

                <CKEditor
                  editor={ClassicEditor}
                  data={shippinginfo}
                  onReady={(editor) => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setShippingInfo(data);
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                />
              </div>
              <div className="col-md-6">
                <label className="p-2">Refund Policy</label>

                <CKEditor
                  editor={ClassicEditor}
                  data={refundpolicy}
                  onReady={(editor) => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setRefundPolicy(data);
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                />
              </div>
              <div className="col-md-6">
                <label className="p-2">Status</label>
                <select
                  name="status"
                  className="form-control"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="select">--select--</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>
              <div className="col-md-6 p-3">
                <label className="formleb">
                  Check mark if you want to add this product in
                </label>
                <br />
                <div className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    class="largerCheckbox"
                    onClick={(e) => setBestsellcheck(e.target.checked)}
                    checked={bestsellcheck}
                  />
                  &nbsp;&nbsp;&nbsp; <span>Bestseller</span>&nbsp;&nbsp;&nbsp;
                  <input
                    type="checkbox"
                    class="largerCheckbox"
                    onClick={(e) => setNewarrivals(e.target.checked)}
                    checked={newarrivals}
                  />
                  &nbsp;&nbsp;&nbsp; <span>New Arrivals</span>
                </div>
              </div>

              <div className="col-md-6 p-3">
                <label className="formleb">
                  Check mark if you want to add this baby blood group field{" "}
                </label>
                <br />
                <div className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    class="largerCheckbox"
                    onClick={(e) => setMomImage(e.target.checked)}
                    checked={momImage}
                  />
                  &nbsp;&nbsp;&nbsp; <span>Mom picture</span>&nbsp;&nbsp;&nbsp;
                  <input
                    type="checkbox"
                    class="largerCheckbox"
                    onClick={(e) => setDadImage(e.target.checked)}
                    checked={dadImage}
                  />
                  &nbsp;&nbsp;&nbsp; <span>Dad picture</span>&nbsp;&nbsp;&nbsp;
                  <input
                    type="checkbox"
                    class="largerCheckbox"
                    onClick={(e) => setParentsImage(e.target.checked)}
                    checked={parentsImage}
                  />
                  &nbsp;&nbsp;&nbsp; <span>Parents picture</span>
                  &nbsp;&nbsp;&nbsp;
                </div>
              </div>

              <div className="col-md-6 p-3">
                <label className="formleb">Type Number Of Baby Images</label>
                <br />
                <div className="d-flex align-items-center">
                  <input
                    type="number"
                    class="form-control"
                    onChange={(e) => setNumberOfBabyImage(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-6 p-3">
                <label className="formleb">Type Number Of Parents Images</label>
                <br />
                <div className="d-flex align-items-center">
                  <input
                    type="number"
                    class="form-control"
                    onChange={(e) => setNumberOfParentsImage(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div>
                  <hr />
                  <h2 className="text-center">Varients</h2>
                  <div className="row">
                    <div className="col-md-6 p-3">
                      <label>Select Size</label>

                      <select
                        className="form-control"
                        onChange={(e) => setSize(e.target.value)}
                      >
                        <option className="" value="">
                          --select--
                        </option>
                        {sizes?.map((item, index) => (
                          <>
                            <option className="" key={index} value={item.name}>
                              {item.name}
                            </option>
                          </>
                        ))}
                      </select>
                    </div>

                    <div className="col-md-6 p-3">
                      <label>Mrp As Per Size</label>

                      <input
                        className="form-control"
                        type="number"
                        name="vmrp"
                        value={vmrp}
                        placeholder="Enter amount(MRP)"
                        onChange={(e) => setVmrp(e.target.value)}
                      />
                    </div>

                    <div className="col-md-6 p-3">
                      <label> Price As Per Size</label>

                      <input
                        className="form-control"
                        type="number"
                        name="vprive"
                        value={vprice}
                        placeholder="Enter amount(price)"
                        onChange={(e) => setVprice(e.target.value)}
                      />
                    </div>

                    <div className="col-md-6 p-3">
                      <label>Check if you wanna set it default </label>
                      <br />
                      <input
                        className="largerCheckbox"
                        type="checkbox"
                        placeholder="set to default"
                        onClick={(e) => setVerityDef(e.target.checked)}
                        checked={verityDef}
                      />
                    </div>
                  </div>

                  <button
                    className="btn btn-primary"
                    onClick={() => addproductV()}
                  >
                    {" "}
                    add option
                  </button>
                </div>
              </div>
              <div className="col-md-12">
                <Table striped bordered hover>
                  {productvarient.length > 0 ? (
                    <>
                      {" "}
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Size</th>
                          <th>Mrp</th>

                          <th>Price</th>

                          <th>default</th>
                        </tr>
                      </thead>
                      <tbody>
                        {productvarient.length > 0
                          ? productvarient.map((item, index) => (
                              <>
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{item.size}</td>
                                  <td>{item.mrp}</td>
                                  <td>{item.price}</td>
                                  <td>{item.set_default ? "True" : "false"}</td>
                                </tr>
                              </>
                            ))
                          : ""}
                      </tbody>
                    </>
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "200px", width: "100%" }}
                    >
                      <div>No record Added</div>
                    </div>
                  )}
                </Table>
              </div>

              <hr />

              <button
                type="submit"
                className="btn btn-sm btn-primary mt-3 ms-3"
                style={{ width: "60px" }}
                onClick={() => AddProduct()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddProduct;
