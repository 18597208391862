import Dashboard from "../../Dashboard";
import AllOrder from "./Order";

const Failed=()=>{
    return(
        <>
<AllOrder  status="Cancelled"/>
        </>
    );
}
export default Failed;