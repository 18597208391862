import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Container, Row } from "react-bootstrap";
import NavBar from "../../../NavBar";
import Table from "react-bootstrap/Table";
import Image from "react-bootstrap/Image";
import Nav from "react-bootstrap/Nav";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
const Testimonial = () => {
  const [testimonial, setTestimonial] = useState([]);
  const [image, setImage] = useState("");
  const [status, setStatus] = useState("Active");
  const [heading, setHeading] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState();

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [gethomeCategory, setGethomeCategory] = useState([]);
  const [show, setShow] = useState(false);
  const [id, setId] = useState("");
  const navigate = useNavigate();

  const getallTestimonial = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getTestimonial/${currentPage}`, {
        headers: {
          version: "1.0.0",
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          // toast.error(response.data.message);
          if (response.data.error_code == 461) {
            navigate("/SignIn");
          }
        } else {
          // toast.success(response.data.message);
          console.log("response.data.data", response.data.data);
          setTestimonial(response.data.data);
          setPages(response.data.remainingCount);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deletehomecategory = (id) => {
    if (window.confirm("Do you really want to delete this item?")) {
    } else {
      return;
    }
    const token = localStorage.getItem("token");
    axios
      .delete(`${BASE_URL}admin/deleteTestimonial/${id}`, {
        headers: {
          version: "1.0.0",
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          toast.error(response.data.message);
          if (response.data.error_code == 461) {
            navigate("/SignIn");
          }
        } else {
          toast.error(response.data.message);
          getallTestimonial();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getallTestimonial();
  }, [currentPage]);

  // const updateHomeCategory=()=>{
  //   const token=localStorage.getItem("token");
  //   const obj={
  //     name:name
  //   }
  //   axios
  //   .put(`${BASE_URL}admin/updateHomeCategory/${id}`,obj,{
  //     headers:{
  //       "version":"1.0.0",
  //       "x-access-token":`${token}`,
  //       "Content-Type":"Application/json"
  //     }
  //   }).then((response)=>{
  //     if(response.data.success==false){
  //       toast.error(response.data.message);
  //       if(response.data.error_code==461)
  //       {
  //           navigate("/SignIn");
  //       }
  //     }else{
  //       toast.success(response.data.message);
  //       getallhomecategory();
  //     }
  //   }).catch((error)=>{
  //     console.log(error);
  //   })
  // }

  // const saveChanges=()=>{
  //   updateHomeCategory();
  // }

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getallTestimonial(page);
  };
  return (
    <>
      <NavBar />
      <Container fluid style={{ height: "100vh", backgroundColor: "#f8f9fe;" }}>
        <Container>
          <br />
          <br />
          <span
            style={{ fontSize: "20px", fontWeight: "600" }}
            className="pt-3"
          >
            Testimonial Listing
          </span>

          <div className="scrollit">
            <hr
              style={{ width: "165px", fontWeight: "700", color: "grey" }}
            ></hr>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>S no</th>

                  <th>Image</th>
                  <th> Name</th>
                  {/* <th> Heading</th> */}
                  <th>Feedback</th>
                  <th>Rating</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {testimonial.map((item, index) => (
                  <tr key={index}>
                    <td className="tabletd">{++index}</td>

                    <td>
                      <img
                        src={item.image}
                        className="img-fluid"
                        alt=""
                        width={40}
                        height={40}
                      />
                    </td>

                    <td>{item.name}</td>
                    <td>{item.description}</td>

                    <td>
                      {Array.from({ length: item.rate }, (_, i) => i + 1).map(
                        (rate) => (
                          <>
                            <i key={rate} className="fa fa-star"></i>
                          </>
                        )
                      )}
                    </td>
                    <td>
                      <button type="button" class="btn btn-danger ">
                        <i
                          className="fa fa-trash"
                          onClick={() => deletehomecategory(item._id)}
                        ></i>{" "}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* pagination  */}
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li
                    class={`page-item ${currentPage === 1 ? "disabled" : ""} `}
                  >
                    <a
                      class="page-link"
                      href="#"
                      aria-label="Previous"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>

                  {Array.from({ length: pages }, (_, i) => i + 1).map(
                    (page) => (
                      <>
                        <li
                          key={page}
                          className={`page-item ${
                            page === currentPage ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(page)}
                          >
                            {page}
                          </button>
                        </li>
                      </>
                    )
                  )}
                  <li
                    class={`page-item ${
                      currentPage === pages ? "disabled" : ""
                    }`}
                  >
                    <a
                      class="page-link"
                      href="#"
                      aria-label="Next"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
              {/* pagination end  */}
            </Table>
          </div>
        </Container>
      </Container>
    </>
  );
};
export default Testimonial;
