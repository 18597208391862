import React from 'react'
import { useLocation } from 'react-router-dom';
import moment from "moment"

export default function PrintInvoice() {
    const state = useLocation();
    const item = state.state;
    console.log("item", item);
    return (
        <>
            <div className='container'>

<div className='my-5 d-flex justify-content-center'>
<button onClick={()=>window.print()}>Print</button>

</div>
                <div className='row '>
                    <h2>Invoice {item.user_Order_ID}</h2>
                    <div className='col-6 printinvoiceTB'>
                        <tr >
                            <th style={{ width: "200px" }}>Customer name:</th>
                            <td>{item.name}</td>
                        </tr>
                        <hr />
                        <tr>
                            <th style={{ width: "200px" }}> Mobile number:</th>

                            <td>{item.mobile_no}</td>
                        </tr>
                        <hr className='m-1' />
                        <tr>
                            <th style={{ width: "200px" }}>Email Id:</th>
                            <td>{item.email}</td>
                        </tr>
                        <hr className='m-1' />
                        <tr>
                            <th style={{ width: "200px" }}>Address 1:</th>
                            <td>{item.address}</td>
                        </tr>
                        <hr className='m-1' />
                        <tr>
                            <th style={{ width: "200px" }}>Address 2:</th>
                            <td>{item.address1}</td>
                        </tr>
                        <hr className='m-1' />
                        <tr>
                            <th style={{ width: "200px" }}>Country:</th>
                            <td>{item.country}</td>
                        </tr>
                        <hr className='m-1' />
                        <tr>
                            <th style={{ width: "200px" }}>State:</th>
                            <td>{item.state}</td>
                        </tr>
                        <hr className='m-1' />
                        <tr>
                            <th style={{ width: "200px" }}>State:</th>
                            <td>{item.city}</td>
                        </tr>
                        <hr className='m-1' />
                        <tr>
                            <th style={{ width: "200px" }}>Pin code :</th>
                            <td>{item.zip_code}</td>
                        </tr>
                        <hr className='m-1' />
                        <tr>
                            <th style={{ width: "200px" }}>Order Type :</th>
                            <td>{item.delivery_type}</td>
                        </tr>
                        <hr className='m-1' />
                        <tr>
                            <th style={{ width: "200px" }}>Order Date :</th>
                            <td>{moment(item.createdAt).format('YYYY-MM-DD')}</td>
                        </tr>
                        <hr className='m-1' />
                    </div>

                </div>
                <div className='row'>
                    <div className='col-12'>
                    <div className="mt-5">
                          
<div className='row my-5'>
    {/* <div className='col-4 p-0'>

        <div><strong>Product Name:</strong></div>
        <hr className='m-0'/>
        <div><strong>Color:</strong></div>
        <hr className='m-0'/>
        <div><strong>Size:</strong></div>
        <hr className='m-0'/>
        <div><strong>Quantity:</strong></div>
        <hr className='m-0'/>
        <div><strong>Amount:</strong> </div>
        <hr className='m-0'/>
        <div><strong>Discount amount:</strong></div>
        <hr className='m-0'/>
        <div><strong>Shipping amount:</strong></div>
        <hr className='m-0'/>
        <div><strong>Total Amount:</strong></div>
    </div> */}
    {/* <div className='col-12 p-0'> */}
        {
            item.cart.length>0&&item.cart.map((item1, index) => (
                <>
                <div className='col-6 p-0'>
                <div className='py-2'><strong>Product Name:</strong></div>
        <hr className='m-0'/>
                </div> 
                <div className='col-6 p-0 '>
                <div className='py-2'>{item1.Product_details[0].name?item1.Product_details[0].name:"----"}</div>
                <hr className='m-0'/>
                </div>
                <div className='col-6 p-0'>
                <div className='py-2'><strong>Size:</strong></div>
        <hr className='m-0'/>
                </div>
                <div className='col-6 p-0'>
                <div className='py-2'>{item1.size?item1.size:"----"}</div>
                <hr className='m-0'/>
                </div>
                {/* <div className='col-6 p-0'>
                <div className='py-2'><strong>Color:</strong></div>
        <hr className='m-0'/>
                </div>
                <div className='col-6 p-0'>
                <div className='py-2'> {item1.color?item1.color:"----"}</div>
                <hr className='m-0'/>
                </div> */}
                <div className='col-6 p-0'>
                <div className='py-2'><strong>Quantity:</strong></div>
        <hr className='m-0'/>
                </div>
                <div className='col-6 p-0'>
                <div className='py-2'>{item1.quantity?item1.quantity:"----"}</div>
                <hr className='m-0'/>
                </div>
                <div className='col-6 p-0'>
                <div className='py-2'><strong>Price:</strong></div>
        <hr className='m-0'/>
                </div>
                <div className='col-6 p-0'>
                <div className='py-2'>{item1.price?item1.price:item1.Product_details[0].price}</div>
                <hr className='m-0'/>
                </div>
                <br/>
                <br/>
                <br/>
                <br/>

        {/* <button onClick={()=>console.log(item)}>click</button> */}
                </>
            )) 
        }
    
    <div className='col-6 p-0'>
    <div className='py-2'><strong>Amount:</strong></div>
        <hr className='m-0'/>
                </div>
                <div className='col-6 p-0'>
                <div className='py-2'>{item.amount?item.amount:"----"}</div>
        <hr className='m-0'/>
                </div>
 
                <div className='col-6 p-0'>
                <div className='py-2'><strong>Discount Amount:</strong></div>
        <hr className='m-0'/>
                </div>
                <div className='col-6 p-0'>
                <div className='py-2'>{item.discount_amount?item.discount_amount:"----"}</div>
        <hr className='m-0'/>
                </div>
                <div className='col-6 p-0'>
                <div className='py-2'><strong>Shipping Amount:</strong></div>
        <hr className='m-0'/>
                </div>
                <div className='col-6 p-0'>
                <div className='py-2'>{item.shipping_charge?item.shipping_charge:"----"}</div>
        <hr className='m-0'/>
                </div>
                <div className='col-6 p-0'>
                <div className='py-2'><strong>Total Amount:</strong></div>
        <hr className='m-0'/>
                </div>
                <div className='col-6 p-0'>
                <div className='py-2'>{item.total_amount?item.total_amount:"----"}</div>

        <hr className='m-0'/>
                </div>
     

        
     

    {/* </div> */}

</div>
                                 
                               
                        </div>

                </div>
            </div>
        </div >
        </>
    )
}
