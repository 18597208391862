import react from "react"
import AllOrder from "./Order";

const ReplaceOrders=()=>{
 

    return(
    <>
    <AllOrder status="Replace"/>
    </>
    );
} 
export default ReplaceOrders;