import { useEffect, useState } from "react";
import NavBar from "../../NavBar";
import axios from "axios";
import { toast } from "react-toastify";
import { set } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const ShippingCharges=()=>{
    const [id , setId]=useState("");
    const [cod_charge , setCod_Charge]=useState();
    const [prepaid_charge , setPrepaid_Charge]=useState();
    const [free_shipping_order_amount , setFree_shipping_order_amount]=useState();
    // const [shpcrg , setShpCrg]=useState([]);
    const BASE_URL=process.env.REACT_APP_BASE_URL;
    const token=localStorage.getItem("token");
    const navigate =useNavigate();
    const addshipcharge=()=>{
        const obj={
            cod_charge:cod_charge,
            prepaid_charge:prepaid_charge,
            free_shipping_order_amount:free_shipping_order_amount
        }
        if(id)
        {
            obj.id=id;
        }
      
        axios
        .post(`${BASE_URL}admin/addShippingCharges`,obj,{
            headers:{
                "Content-Type":"Application/json",
                "version":"1.0.0",
                "x-access-token":`${token}`
            }
        }).then((response)=>{
            if(response.data.success==false)
            {
                toast.error(response.data.message);
            }else{
                toast.success(response.data.message);
            }
        }).catch((error)=>{
            console.log(error);
        })
    }

    const getshipcharges=()=>{
    const token=localStorage.getItem("token");

        axios
        .get(`${BASE_URL}admin/getShippingCharges`,{
            headers:{
                "Content-Type":"Application/json",
                "version":"1.0.0",
                "x-access-token":`${token}`
            }
        }).then((response)=>{
            if(response.data.success==false)
            {
                if(response.data.error_code==461)
                {
                    navigate("/SignIn");
                }
                // toast.error(response.data.message);
            }else{
                // toast.success(response.data.message);
                // setShpCrg(response.data.data);
                // console.log("shipcharges",response.data.data);
                setCod_Charge(response.data.data.cod_charge)
                setPrepaid_Charge(response.data.data.prepaid_charge)
                setFree_shipping_order_amount(response.data.data.free_shipping_order_amount);
                // console.log("ship",response.data.data.free_shipping_order_amount);
                // console.log("pre",response.data.data.prepaid_charge);
                // console.log("chod",response.data.data.cod_charge);
                if(response.data.data._id)
                {
                    setId(response.data.data._id);
                }
            }
        }).catch((error)=>{
            console.log(error);
        })
    }

    useEffect(()=>{
        getshipcharges();
    },[])
    return(
        <>
             <NavBar/>
<div className='container '>
    <div className='row d-flex justify-content-center align-items-center mt-5'>
        <div className='col-10 p-5' style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
      <div className="row">
        <div className="col-md-6">
            <label className="p-3">COD charges<span style={{color:"red"}}>*</span></label>
            <input type="text"
             className="form-control"
           name="cod_charge"
           value={cod_charge}
           onChange={(e)=>setCod_Charge(e.target.value)}
            />

        </div>
        <div className="col-md-6">
            <label className="p-3">Prepaid charges <span style={{color:"red"}}>*</span></label>
            <input type="text" className="form-control" 
            name="prepaid_charge"
            value={prepaid_charge}
            onChange={(e)=>setPrepaid_Charge(e.target.value)}
            />

        </div>

        <div className="col-md-6">
            <label className="p-3">Free shipping on orders above Rs.<span style={{color:"red"}}>*</span></label>
            <input type="text" className="form-control"
           name="free_shipping_order_amount"
           value={free_shipping_order_amount}
           onChange={(e)=>setFree_shipping_order_amount(e.target.value)}
            />

        </div>
        <div className="col-md-2 d-flex justify-content-center align-items-end mb-1">
        <button type="sumbit" className='btn btn-sm btn-primary mt-3 ms-2' onClick={()=>addshipcharge()}>Save</button>

        </div>
    
      </div>

        </div>
    </div>


</div>
        </>
    );
}
export default ShippingCharges;