// import Dashboard from "../../Dashboard";

import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import NavBar from "../../NavBar";

const AllOrder = (props) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [id, setId] = useState("");
  const [status, setstatus] = useState("");
  const [orders, setOrders] = useState([]);
  const [show, setShow] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState();
  const navigate = useNavigate();
  const getAllOrders = () => {
    const token = localStorage.getItem("token");

    axios
      .post(
        `${BASE_URL}admin/getAllOrder`,
        { ...props, page: currentPage },
        {
          headers: {
            "x-access-token": `${token}`,
            version: "1.0.0",
            "Content-type": "Application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success == false) {
          console.log(response.data.message);
        } else {
          setOrders(response.data.data);
          console.log("orders", response.data.data);
          setPages(response.data.remainingCount);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changeStatus = () => {
    const token = localStorage.getItem("token");

    const obj = {
      _id: id,
      status: status,
    };
    axios
      .post(`${BASE_URL}admin/changeStatus`, obj, {
        headers: {
          "Content-Type": "Application/json",
          "x-access-token": `${token}`,
          version: "1.0.0",
        },
      })
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message);
          getAllOrders();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/SignIn");
    }
  }, []);
  const openModel = (id) => {
    setShow(true);
    setId(id);
  };
  useEffect(() => {
    getAllOrders();
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getAllOrders(page);
  };

  return (
    <>
      <NavBar />
      <div className="container ">
        <br />
        <br />
        <span style={{ fontSize: "20px", fontWeight: "600" }} className="p-2">
          {props.status} Orders List
        </span>
        <div className="scrollit">
          <hr style={{ width: "165px", fontWeight: "700", color: "grey" }}></hr>
          <Table striped bordered hover>
            <thead>
              <tr>
                {/* <th>#</th> */}
                <th>S no.</th>
                <th> Order Id</th>
                <th> Customer name</th>
                <th> delivery_type</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {orders.length > 0 &&
                orders.map((item, index) => (
                  <>
                    <tr key={index}>
                      <td className="tabletd">{index + 1}</td>
                      <td>{item.user_Order_ID}</td>
                      <td>{item.name}</td>
                      <td>{item.delivery_type}</td>
                      <td>{item.total_amount}</td>
                      <td>{item.status}</td>

                      <td
                        class=""
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <button
                          class="btn btn-primary btn-sm"
                          onClick={() =>
                            navigate("/order-details", { state: item })
                          }
                        >
                          <i className="fa fa-eye"></i>{" "}
                        </button>
                        &nbsp;&nbsp;
                        <button
                          class="btn btn-primary btn-sm"
                          onClick={() =>
                            navigate("/print-invoice", { state: item })
                          }
                        >
                          <i className="fa fa-print"></i>{" "}
                        </button>
                        &nbsp;&nbsp;
                        <button
                          class="btn btn-primary btn-sm"
                          style={{ fontSize: "12px" }}
                          onClick={() => openModel(item._id)}
                        >
                          Change <br />
                          status{" "}
                        </button>
                      </td>
                    </tr>
                  </>
                ))}
            </tbody>
            {/* pagination  */}
            <nav aria-label="Page navigation example">
              <ul class="pagination">
                <li class={`page-item ${currentPage === 1 ? "disabled" : ""} `}>
                  <a
                    class="page-link"
                    href="#"
                    aria-label="Previous"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                {Array.from({ length: pages }, (_, i) => i + 1).map((page) => (
                  <>
                    <li
                      key={page}
                      className={`page-item ${
                        page === currentPage ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(page)}
                      >
                        {page}
                      </button>
                    </li>
                  </>
                ))}
                <li
                  class={`page-item ${currentPage === pages ? "disabled" : ""}`}
                >
                  <a
                    class="page-link"
                    href="#"
                    aria-label="Next"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
            {/* pagination end  */}
          </Table>
        </div>

        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Change order status</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label className="mt-3 mb-1">Select Status:</label>
            <select
              className="form-control text "
              onChange={(e) => setstatus(e.target.value)}
            >
              <option value="">--select--</option>
              <option value="Pending">Pending</option>
              <option value="Process">Process</option>
              <option value="Shipped">Shipped</option>
              <option value="Cancelled">Cancelled</option>
              <option value="Delivered">Delivered</option>
              <option value="Return">Return</option>
              <option value="Replace">Replace</option>
            </select>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={() => changeStatus()}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};
export default AllOrder;
