import axios from "axios";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { toast } from "react-toastify";
import NavBar from "../../NavBar";
import { useNavigate } from "react-router-dom";

const AllUserList=()=>{
  const BASE_URL=process.env.REACT_APP_BASE_URL;
  const [alluser , setAllUser]=useState([]);
  const navigate=useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
    const [pages, setPages] = useState();
  const getAllUser=()=>{
    const token=localStorage.getItem("token");
    axios
    .post(`${BASE_URL}admin/getAllUser/${currentPage}`,{},{
      headers:{
        "Content-Type":"Application/json",
        "x-access-token":`${token}`,
        "version":"1.0.0"
      }
    }).then((response)=>{
      if(response.data.success===false)
      {
        toast.error(response.data.message);
      }else{
        // toast.success(response.data.message);
        setAllUser(response.data.data);
        setPages(response.data.remainingCount);
        console.log(response.data.data);
      }
    }).catch((error)=>{
      console.log(error);
    })
  }
  const changeStatus=(id)=>{
    const token = localStorage.getItem("token");

    axios.post(`${BASE_URL}admin/changeuserStatus/${id}`,{},{
      headers:{
        "Content-Type":"Application/json",
        "version":"1.0.0",
        "x-access-token":`${token}`
      }
    }).then((res)=>{
      if(res.data.success){
        toast.success(res.data.message);
        getAllUser();
      }else{
        toast.error(res.data.message);
      }
    }).catch((err)=>{
      console.log(err.message);
    })

  }
  const handlePageChange = (page) => {
    setCurrentPage(page);
    getAllUser(page);
  
  }
  useEffect(()=>{
    getAllUser();
  },[currentPage])


    return(
        <>
        <NavBar/>
        <div className="container p-3">

       
         <div className="scrollit">
            <span style={{ fontSize: '20px', fontWeight: '600' }} className="p-2">All User List</span>
            <hr style={{ width: '165px', fontWeight: '700', color: 'grey' }}></hr>

      
           <Table striped bordered hover >
              <thead>
                <tr>
                  {/* <th>#</th> */}
                  <th>S no.</th>

                  <th>Name</th>
                  {/* <th> Mobile no.</th> */}
                  <th>Email Id:</th>
                  <th>Mobile number:</th>
                  <th>City:</th>
                  <th>created At:</th>


                  <th>Status:</th>
                  {/* <th>Date:</th> */}
                  {/* <th>Date:</th> */}
                 <th>Action</th> 

                </tr>
              </thead>
              <tbody>

                {
                 alluser.map((item, index) => (
                    <tr key={index}>
  <td className="tabletd">
                        {/* <Image src={item.icon} style={{ width: '40px' }}></Image> */}
                        {++index}
                      </td>
                      <td>
                        {/* <Image src={item.icon} style={{ width: '40px' }}></Image> */}
                        {item.name}
                      </td>
                      {/* <td>
                        {item.mobile_no}
                        </td> */}
                      <td>
                        {item.email}
                        
                      </td>
                       <td>
                        {item.mobile_no}
                        
                      </td>
                      <td>
                        {item.city}
                        
                      </td>
                      <td>
                        {item.createdAt}
                        
                      </td>
                      <td>
                        {item.status}
                        
                      </td>
                      {/* <td>
                        {item.date}
                        
                      </td> */}
                      <td>
                      <button type="button" class="btn btn-primary btn-sm m-1 " onClick={()=>changeStatus(item._id)} style={{
                        width:"40px",
                        overflow:"hidden",
                        whiteSpace:"nowrap",
                        textOverflow:"ellipsis",

                      }}><abbr title="Change Status">Change Status</abbr> </button>
                    &nbsp;
                 <br/>
                 
                      <button type="button" class="btn btn-primary btn-sm m-1 " style={{
                        width:"40px",
                        overflow:"hidden",
                        whiteSpace:"nowrap",
                        textOverflow:"ellipsis",

                      }} 
                      onClick={()=>navigate("/view-user" ,{state:item})}><abbr title="View  User">View  User</abbr> </button>

                        
                      </td>
                  
                    </tr>

                  ))
                }

                {/* <tr>
                  <td>2</td>
                  <td><Image src="#" style={{ width: '40px' }}></Image></td>
                  <td>Thornton</td>
                  <td>InActive</td>
                  <td>
                    <button type="button" class="btn btn-primary "><i className="fa fa-edit"></i> </button>
                    &nbsp; &nbsp;
                    <button type="button" class="btn btn-danger "><i className='fa fa-trash'></i> </button>
                  </td>
                </tr> */}
                {/* <tr>
                  <td>3</td>
                  <td ><Image src="#" style={{ width: '40px' }}></Image></td>
                  <td>gujh</td>
                  <td>Active</td>
                  <td>
                    <button type="button" class="btn btn-primary "><i className="fa fa-edit"></i> </button>
                    &nbsp; &nbsp;
                    <button type="button" class="btn btn-danger "><i className='fa fa-trash'></i> </button>
                  </td>
                </tr> */}
              </tbody>
                {/* pagination  */}
                <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class={`page-item ${currentPage === 1 ? "disabled" : ""} `}>
                    <a class="page-link" href="#" aria-label="Previous" onClick={() => handlePageChange(currentPage - 1)}>
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                

                  {Array.from({ length: pages }, (_, i) => i + 1).map((page) => (
                    <>
                      <li key={page} className={`page-item ${page === currentPage ? "active" : ""}`}>
                        <button className="page-link" onClick={() => handlePageChange(page)}>
                          {page}
                        </button>
                      </li>
                    </>
                  ))}
                  <li class={`page-item ${currentPage === pages ? "disabled" : ""}`}>
                    <a class="page-link" href="#" aria-label="Next" onClick={() => handlePageChange(currentPage + 1)}>
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
              {/* pagination end  */}
            </Table>
            </div>
            </div>
        </>
    );
}
export default AllUserList;