import Dashboard from "../../Dashboard";
import AllOrder from "./Order";

const Delivered=()=>{
    return(
        <>
        <AllOrder status="Delivered"/>
        </>
    );
}
export default Delivered;