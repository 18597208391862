import { Container, Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import NavBar from "../../../NavBar";
import { useLocation, useNavigate } from "react-router-dom";

const EditCategory = () => {
  const { state } = useLocation();
  const [name, setName] = useState(state.item.name);
  const [file, setFile] = useState(state.item.icon);
  const [status, setStatus] = useState(state.item.status);
  const [heading, setHeading] = useState(state.item.heading1);
  const [description, setDescription] = useState(state.item.description);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [token, setToken] = useState("");
  const [id, setId] = useState(state.item._id);
  const navigate = useNavigate();
  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
      setToken(token);
    } else {
      navigate("/SignIn");
    }
  }, [token]);

  const Save = async (data) => {
    if (name.length == 0 && name == "") {
      toast.error("Category name is required");
      return;
    }
    if (heading.length == 0 || heading == "") {
      toast.error(" Category heading  is required");
      return;
    }
    const fromData = new FormData();
    fromData.append("name", name);
    fromData.append("image", file[0]);
    fromData.append("status", status);
    fromData.append("description", description);
    fromData.append("heading1", heading);
    fromData.append("id", id);

    axios
      .post(`${BASE_URL}admin/updateCategory`, fromData, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "multipart/form-data",
          Version: "1.0.0",
        },
      })
      .then(function (response) {
        if (response.data.success == false) {
          toast.error(response.data.message);
        } else {
          toast.success(response.data.message);
          setName("");
          setFile("");
          navigate("/Category");
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  return (
    <>
      <NavBar />

      <Container fluid style={{ height: "100vh", backgroundColor: "#f8f9fe;" }}>
        <Container>
          <Row className="mb-5">
            <Col md={1}></Col>
            <Col>
              <div className="p-5 mt-5 form">
                <div
                  className="dashboard mb-2 text-center"
                  style={{ fontSize: "25px" }}
                >
                  Update Category
                </div>
                <label className="pb-1 pt-3 ">Category name:</label>

                <input
                  type="text"
                  className="text form-control "
                  placeholder="Enter category name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <label className="pb-1 pt-3 "> Heading:</label>
                <input
                  type="text"
                  className="text form-control "
                  placeholder="Enter heading"
                  name="heading"
                  value={heading}
                  onChange={(e) => setHeading(e.target.value)}
                />
                <label className="pb-1 pt-3 ">Description:</label>

                <input
                  type="text"
                  className="text form-control "
                  placeholder="Enter description"
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <label className="pb-1 pt-3 ">Image:</label>
                {/* <p>{file}</p> */}
                <input
                  type="file"
                  className="text form-control file"
                  name="file"
                  onChange={(e) => setFile(e.target.files)}
                ></input>

                <img
                  src={file}
                  className="img-fluid"
                  width={100}
                  height={100}
                />
                <br />
                <label className="pb-1 pt-3 ">Status:</label>

                <select
                  name="status"
                  className="text form-control "
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
                <button
                  type="button"
                  class="btn btn-primary mt-3"
                  onClick={() => Save()}
                >
                  {" "}
                  Update{" "}
                </button>
              </div>{" "}
            </Col>
            <Col md={1}></Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};
export default EditCategory;
