import React, { useRef, useState } from 'react';
// import flower1 from "../FrameDecorationAssets/flower1.png"
import { useLocation } from 'react-router-dom';
import calender from "../FrameDecorationAssets/calender.jpeg";
// import clock from "../FrameDecorationAssets/clock.png";
// import weightCal from "../FrameDecorationAssets/weight-calculator.jpg";
// import Hospital from "../FrameDecorationAssets/Hospital.jpeg"
import gender from "../FrameDecorationAssets/gender.jpeg";
// import redflower from "../FrameDecorationAssets/red-flower.png";
// import leftCornerFlower from "../FrameDecorationAssets/LeftCornerRedFlower.png";
// import rightCornerFlower from "../FrameDecorationAssets/RightCornerRedFlower.png";
import BoyDoll from "../FrameDecorationAssets/boydoll.png";
import doll from "../FrameDecorationAssets/doll.png";
import Calender from "../FrameDecorationAssets/Calender4.png";
import Hospital from "../FrameDecorationAssets/Hospital.jpeg";
import weightCal from "../FrameDecorationAssets/weight.jpeg";
import clock from "../FrameDecorationAssets/clock3.png";
import parents from "../FrameDecorationAssets/animatedParent.png";
import babyanimatedImage from "../FrameDecorationAssets/baby.png";
import cake from "../FrameDecorationAssets/cake.png";
import stars from "../FrameDecorationAssets/stars.png";

import moment from "moment"

const FourthA3 = () => {
  const { state } = useLocation();
  console.log(state.state.item.framedata[0])
  let state1 = state.state.item.framedata[0];


  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);
  const [initialX, setInitialX] = useState(0);
  const [initialY, setInitialY] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [zoomLevel1, setZoomLevel1] = useState(1);
  const [zoomLevel2, setZoomLevel2] = useState(1);
  const [zoomLevel3, setZoomLevel3] = useState(1);
  const [zoomLevel4, setZoomLevel4] = useState(1);
  const [zoomLevel5, setZoomLevel5] = useState(1);

  const [rotateFrame1, setRotateFrame1] = useState(0);
  const [rotateFrame2, setRotateFrame2] = useState(0);
  const [rotateFrame3, setRotateFrame3] = useState(0);
  const [rotateFrame4, setRotateFrame4] = useState(0);
  const [rotateFrame5, setRotateFrame5] = useState(0);




  // Function to handle mouse down event
  const handleMouseDown = (e) => {
    // Store the initial mouse position and image position
    setOffsetX(e.clientX);
    setOffsetY(e.clientY);
    setInitialX(e.target.offsetLeft);
    setInitialY(e.target.offsetTop);
    setIsDragging(true);

    // Attach event listener to handle mouse move event
    document.addEventListener("mousemove", handleMouseMove);
    // Attach event listener to handle mouse up event
    document.addEventListener("mouseup", handleMouseUp);
  };


  const handleMouseMove = (e) => {
    if (isDragging) {

      const newX = initialX + e.clientX - offsetX;
      const newY = initialY + e.clientY - offsetY;
      console.log(newX);
      console.log(newY);
      e.target.style.left = newX + "px";
      e.target.style.top = newY + "px";
    }
  };
  // Function to handle mouse up event
  const handleMouseUp = () => {
    setIsDragging(false);
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const imageRef = useRef(null);

  // Function to handle zoom based on mouse wheel event
  const handleZoomIn1 = (e) => {
    setZoomLevel1(zoomLevel1 + 0.1); // Zoom in
  };
  const handleZoomOut1 = (e) => {
    setZoomLevel1(zoomLevel1 - 0.1); // Zoom out
  };

  const handleZoomIn2 = (e) => {
    setZoomLevel2(zoomLevel2 + 0.1); // Zoom in
  };
  const handleZoomOut2 = (e) => {
    setZoomLevel2(zoomLevel2 - 0.1); // Zoom out
  };

  const handleZoomIn3 = (e) => {
    setZoomLevel3(zoomLevel3 + 0.1); // Zoom in
  };
  const handleZoomOut3 = (e) => {
    setZoomLevel3(zoomLevel3 - 0.1); // Zoom out
  };

  const handleZoomIn4 = (e) => {
    setZoomLevel4(zoomLevel4 + 0.1); // Zoom in
  };
  const handleZoomOut4 = (e) => {
    setZoomLevel4(zoomLevel4 - 0.1); // Zoom out
  };

  const handleZoomIn5 = (e) => {
    setZoomLevel5(zoomLevel5 + 0.1); // Zoom in
  };
  const handleZoomOut5 = (e) => {
    setZoomLevel5(zoomLevel5 - 0.1); // Zoom out
  };

  const rotateImageLeft1 = (e) => {
    setRotateFrame1(rotateFrame1 + 2)
  }
  const rotateImageRight1 = (e) => {
    setRotateFrame1(rotateFrame1 - 2)
  }

  const rotateImageLeft2 = (e) => {
    setRotateFrame2(rotateFrame2 + 2)
  }
  const rotateImageRight2 = (e) => {
    setRotateFrame2(rotateFrame2 - 2)
  }

  const rotateImageLeft3 = (e) => {
    setRotateFrame3(rotateFrame3 + 2)
  }
  const rotateImageRight3 = (e) => {
    setRotateFrame3(rotateFrame3 - 2)
  }

  const rotateImageLeft4 = (e) => {
    setRotateFrame4(rotateFrame4 + 2)
  }
  const rotateImageRight4 = (e) => {
    setRotateFrame4(rotateFrame4 - 2)
  }
  const rotateImageLeft5 = (e) => {
    setRotateFrame5(rotateFrame5 + 2)
  }
  const rotateImageRight5 = (e) => {
    setRotateFrame5(rotateFrame5 - 2)
  }
  return (
    <>
      <div className='designFrame' style={{ width: "100%", }}>
        <div className='printme' style={{ width: "100%" }}>
          <div className='' style={{ display: "flex", height: "185px" }}>
            <span className='itim-regular ' style={{ marginTop: "15px", width: "75%", fontSize: "3.5rem", color: "#bf2b28", padding: "0", textAlign: "center", marginBottom: "0", lineHeight: "1.2" }}>
              <p style={{ fontWeight: "900" }}>My Little {state1.baby_gender == "girl" ? "Princess" : "Prince"}</p>
              <p style={{ fontWeight: "900" }}>Welcome {state1.baby_name}</p>
            </span>
            <div className='' style={{ width: "25%" }}>
              <img src={parents} className='' alt='image' style={{ width: "75%" }} />
              <p className='itim-regular' style={{ width: "75%", fontSize: "3.5rem", color: "#bf2b28", padding: "0", textAlign: "center", marginBottom: "0", lineHeight: "1.2" }}>Baby {state1.baby_gender}</p>
            </div>
          </div>
          <div className='' style={{ display: "flex", marginBottom: "5px" }}>
            <div className='' style={{ width: "70%" }}>
              <div className='' style={{ display: "flex" }}>
                <div style={{ width: "46%", textAlign: "center" }}>
                  <img src={cake} className='' alt='img' style={{ width: "50%" }} />
                  <p className='pacifico-regular' style={{ fontSize: "2.5rem", color: "#bf2b28", padding: "0" }}>{moment(state1.baby_dob).format('YYYY-MM-DD')}</p>
                </div>
                <div style={{ width: "40%", textAlign: "center" }}>
                  <img src={clock} className='' alt='img' style={{ width: "57%" }} />
                  <p className='pacifico-regular' style={{ fontSize: "2.5rem", color: "#bf2b28", padding: "0" }}>{state1.baby_tob}</p>
                </div>
              </div>


              <div className='' style={{ display: "flex" }}>
                <div style={{ width: "40%", textAlign: "center" }}>
                  <img src={weightCal} className='' alt='img' style={{ width: "45%" }} />
                  <p className='pacifico-regular' style={{ fontSize: "2.3rem", color: "#3279a8", margin: "0px", lineHeight: "0.8" }}>Weight</p>
                  <p className='itim-regular' style={{ fontSize: "2.3rem", color: "#bf2b28", margin: "0px" }} contentEditable="true">{state1.baby_weight} Kg</p>
                </div>
                <div style={{ width: "20%", textAlign: "center" }}>
                  <img src={stars} className='' alt='img' style={{ width: "100%" }} />
                </div>
                <div style={{ width: "40%", textAlign: "center" }}>
                  <img src={babyanimatedImage} className='' alt='img' style={{ width: "60%" }} />
                </div>
              </div>
            </div>
            <div className='' style={{ width: "35.33%", display: "flex", alignItems: "end" }}>
              <i class="fa fa-rotate-right rotate-icon" onClick={() => rotateImageLeft1()} style={{ position: "relative", left: "40px", zIndex: "10" }} ></i>


              <div className='' style={{ position: "absolute", overflow: "hidden", width: "33%", height: "350px" }}>
                <img src="https://img.freepik.com/free-photo/cute-baby-born_624325-1181.jpg?t=st=1709460995~exp=1709464595~hmac=fafe5b8f262b6a904c034846872828e5385f37be0750875730aac8ea4d726af7&w=826" onMouseDown={handleMouseDown} className='img-fluid baby-image  ' alt='' style={{ position: "relative", objectFit: "cover", cursor: "move", transform: `scale(${zoomLevel1}) rotate(${rotateFrame1}deg)`, transformOrigin: "top left", height: "100%" }} ref={imageRef} onDoubleClick={handleZoomIn1} onProgress={handleZoomOut1} />

              </div>
              <i class="fa fa-rotate-left rotate-icon" onClick={() => rotateImageRight1()} style={{ position: "relative", right: "10px" }}></i>
            </div>
          </div>



          <div style={{ display: "flex" }}>
            <div style={{ textAlign: "center", width: "33.33%", height:"371px" }}>
              <img src={Hospital} className='img-fluid ' alt='' style={{ width: "70%" }} />
              <p className='pacifico-regular' style={{ fontSize: "2.3rem", color: "#3279a8", margin: "0px", lineHeight: "0.8" }}>Hospital</p>
              <p className='itim-regular' style={{ fontSize: "2.3rem", color: "#bf2b28", margin: "0px" }} contentEditable="true">{state1.hospital_name} </p>
            </div>
            <div style={{ textAlign: "center", width: "33.33%",position:"relative",top:"-18px" }}>

              <i class="fa fa-rotate-right rotate-icon" onClick={() => rotateImageLeft2()} style={{ position: "relative", left: "40px", zIndex: "10",top:"30px" }} ></i>


              <div className='' style={{ position: "absolute", overflow: "hidden", width: "99%", height: "350px" }}>
                <img src="https://img.freepik.com/free-photo/cute-baby-born_624325-1181.jpg?t=st=1709460995~exp=1709464595~hmac=fafe5b8f262b6a904c034846872828e5385f37be0750875730aac8ea4d726af7&w=826" onMouseDown={handleMouseDown} className='img-fluid baby-image  ' alt='' style={{ position: "relative", objectFit: "cover", cursor: "move", transform: `scale(${zoomLevel2}) rotate(${rotateFrame2}deg)`, transformOrigin: "top left", height: "100%" }} ref={imageRef} onDoubleClick={handleZoomIn2} onProgress={handleZoomOut2} />

              </div>
              <i class="fa fa-rotate-left rotate-icon" onClick={() => rotateImageRight2()} style={{ position: "relative", right: "10px",top:"30px" }}></i>

            </div>

            <div style={{ textAlign: "center", width: "33.33%",position:"relative",top:"-18px" }}>
              <i class="fa fa-rotate-right rotate-icon" onClick={() => rotateImageLeft3()} style={{ position: "relative", left: "40px", zIndex: "10",top:"30px" }} ></i>


              <div className='' style={{ position: "absolute", overflow: "hidden", width: "99%", height: "350px" }}>
                <img src="https://img.freepik.com/free-photo/cute-baby-born_624325-1181.jpg?t=st=1709460995~exp=1709464595~hmac=fafe5b8f262b6a904c034846872828e5385f37be0750875730aac8ea4d726af7&w=826" onMouseDown={handleMouseDown} className='img-fluid baby-image  ' alt='' style={{ position: "relative", objectFit: "cover", cursor: "move", transform: `scale(${zoomLevel3}) rotate(${rotateFrame3}deg)`, transformOrigin: "top left", height: "100%" }} ref={imageRef} onDoubleClick={handleZoomIn3} onProgress={handleZoomOut3} />

              </div>
              <i class="fa fa-rotate-left rotate-icon" onClick={() => rotateImageRight3()} style={{ position: "relative", right: "10px",top:"30px" }}></i>
            </div>
          </div>

          <div style={{ display: "flex", marginTop: "5px" }}>
            <div style={{ textAlign: "center", width: "33.33%" }}>
              <p className='pacifico-regular' style={{ fontSize: "3rem", color: "#bf2b28" }} contentEditable="true">
                Bless To Have You
              </p>
            </div>
            <div style={{ textAlign: "center", width: "33.33%",position:"relative",top:"-36px"}}>
              {/* <img src="https://i.pinimg.com/236x/ce/e7/68/cee768f874d6814751062fc3d55253ac.jpg" className='img-fluid ' alt='' style={{ width: "98%", height: "340px" }} /> */}


              <i class="fa fa-rotate-right rotate-icon" onClick={() => rotateImageLeft4()} style={{ position: "relative", left: "40px", zIndex: "10",top:"30px" }} ></i>


              <div className='' style={{ position: "absolute", overflow: "hidden", width: "99%", height: "330px" }}>
                <img src="https://img.freepik.com/free-photo/cute-baby-born_624325-1181.jpg?t=st=1709460995~exp=1709464595~hmac=fafe5b8f262b6a904c034846872828e5385f37be0750875730aac8ea4d726af7&w=826" onMouseDown={handleMouseDown} className='img-fluid baby-image  ' alt='' style={{ position: "relative", objectFit: "cover", cursor: "move", transform: `scale(${zoomLevel4}) rotate(${rotateFrame4}deg)`, transformOrigin: "top left", height: "100%" }} ref={imageRef} onDoubleClick={handleZoomIn4} onProgress={handleZoomOut4} />

              </div>
              <i class="fa fa-rotate-left rotate-icon" onClick={() => rotateImageRight4()} style={{ position: "relative", right: "10px",top:"30px"}}></i>
            </div>
            <div style={{ textAlign: "center", width: "33.33%",position:"relative",top:"-36px" }}>
             
            <i class="fa fa-rotate-right rotate-icon" onClick={() => rotateImageLeft5()} style={{ position: "relative", left: "40px", zIndex: "10",top:"30px"}} ></i>


<div className='' style={{ position: "absolute", overflow: "hidden", width: "99%", height: "330px" }}>
  <img src="https://img.freepik.com/free-photo/cute-baby-born_624325-1181.jpg?t=st=1709460995~exp=1709464595~hmac=fafe5b8f262b6a904c034846872828e5385f37be0750875730aac8ea4d726af7&w=826" onMouseDown={handleMouseDown} className='img-fluid baby-image  ' alt='' style={{ position: "relative", objectFit: "cover", cursor: "move", transform: `scale(${zoomLevel5}) rotate(${rotateFrame5}deg)`, transformOrigin: "top left", height: "100%" }} ref={imageRef} onDoubleClick={handleZoomIn5} onProgress={handleZoomOut5} />

</div>
<i class="fa fa-rotate-left rotate-icon" onClick={() => rotateImageRight5()} style={{ position: "relative", right: "10px",top:"30px"}}></i>
            </div>
          </div>
          <div className='' style={{ display: "flex",position:"relative",top:"180px" }}>
            <div style={{ textAlign: "center", width: "33.33%",position:"relative",top:"-10px" }}>
              <p className='pacifico-regular' style={{ fontSize: "3.5rem", color: "#bf2b28", textAlign: "center" }} contentEditable="true"> Proud Parents </p>
            </div>
            <div className='' style={{ padding: "10px", width: "66.66%" }} >
              <p className='protest-riot-regular' style={{ fontSize: "3rem", color: "#bf2b28", padding: 0, lineHeight: "0.7", textAlign: "center" }} contentEditable="true">

                Nirmala Kushwah & Sooraj kushwah</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button className='btn btn-primary' onClick={() => window.print()}> Print</button>
      </div>
    </>
  )
}

export default FourthA3