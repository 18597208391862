import axios from "axios";
import { useEffect, useState } from "react";
import { Image, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import NavBar from "../../../NavBar";
import {  useLocation, useNavigate } from "react-router-dom";

const UpdateSlider=()=>{
    const navigate= useNavigate();
    const {state}=useLocation();
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    // const [allslider , setAllslider]=useState([]);
    const [name , setName]=useState(state.item.name);
    const [file , setFile]=useState("");
    const [status , setStatus]=useState(state.item.status);
    const [title , setTitle]=useState(state.item.title);
    const [heading , setHeading]=useState(state.item.heading1);
    const [slug , setSlug]=useState(state.item.slug);
    const token=localStorage.getItem("token");
    const [id , setId] =useState(state.item._id);
    const maxFileSize=3000000;


    // const getAllSlider=()=>{
    //     axios
    //     .get(`${BASE_URL}admin/getAllSlider`,{
    //         headers:{
    //             "Content-Type":"Application/json",
    //             "x-access-token":`${token}`,
    //             "version":"1.0.0"
    //         }
    //     }).then((response)=>{
    //         if(response.data.success==false)
    //         {
    //             // toast.error(response.data.message);
    //         }else{
    //             // toast.success(response.data.messsage);
    //             setAllslider(response.data.data);
    //             console.log("response",response.data.data);
    //         }
    //     }).catch((error)=>{
    //         console.log(error);
    //     })
    // }

// const updateslide=(id)=>{
//     window.scrollTo(0, 0);
//     setId(id);
// }


    const updSlider=()=>{
    // console.log(file[0].size);


   

 
        const formData= new FormData();
        formData.append("name",name);
        formData.append("status",status);
        // formData.append("slug",slug);
        formData.append("title",title);
        formData.append("heading1",heading);
    
    
        formData.append("image",file[0]);
        // console.log(file);
        
        // let api="addSlider";
        // if(id)
        // {
        //  api="updateSlider";
        //  formData.append("id",id);
        // }
        
// if(file[0]!=" "||file[0]!=undefined)
// {
//     if(file[0].size==""&&file[0].size=="0"&&file[0].size>maxFileSize)
//     {
//      toast.error("uploaded file is too large");
//      return;
//     }
// }
        
    
        axios
        .post(`${BASE_URL}admin/updateSlider/${id}`,formData,{
            headers:{
                "Content-Type":"multipart/formdata",
                "version":"1.0.0",
                "x-access-token":`${token}`
            }
        }).then((response)=>{
            if(response.data.success==false)
            {
                toast.error(response.data.message);
            }
            else{
                toast.success(response.data.message);
                // getAllSlider();
                setId("");
                setName("");
                setFile("");
                setSlug("");
                navigate("/Slider")
                
            }
        })
    }

    useEffect(()=>{
        // getAllSlider();
        // console.log(file);

        console.log("state:============================>",state);
       
    },[])


    // const updateSlider=()=>{


    //     axios
    //     .post(`${BASE_URL}admin/updateSlider/${id}`)
    // }

  
    return(
    <>
     <NavBar />
<div className='container '>
    <div className='row d-flex justify-content-center align-items-center mt-5'>
        <div className='col-10 p-5' style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
      <div className="row">
        <div className="col-md-6">
            <label>Name</label>
            <input type="text" className="form-control"
            name="name"
            value={name}
            onChange={(e)=>setName(e.target.value)}
            />

        </div>
        <div className="col-md-6">
            <label>Select file</label>
            <input type="file" className="form-control" accept=".png , .jpeg , .jpg "
            name="file"
           
           onChange={(e)=>setFile(e.target.files)}
            />

        </div>

        <div className="col-md-6">
            <label>Title</label>
            <input type="text" className="form-control" 
            name="title"
            value={title}
           
           onChange={(e)=>setTitle(e.target.value)}
            />

        </div>
        <div className="col-md-6">
            <label>heading</label>
            <input type="text" className="form-control" 
            name="heading"
            value={heading}
           
           onChange={(e)=>setHeading(e.target.value)}
            />

        </div>

        <div className="col-md-6">
            <label>Slug</label>
            <input type="text" className="form-control"
            name="slug"
            value={slug}
            onChange={(e)=>setSlug(e.target.value)}
            />

        </div>

        <div className="col-md-6">
            <label>Status</label>
            {/* <input type="file" className="form-control"/> */}
            <select className="form-control"
            name="status"
            value={status}
            onChange={(e)=>setStatus(e.target.value)}
            >
                <option>--select--</option>
                <option>Active</option>
                <option>Inactive</option>

            </select>

        </div>
        <button type="submit" className="btn btn-sm btn-primary mt-3 ms-3" style={{width:"60px"}} onClick={()=>updSlider()}>Save</button>
      </div>

        </div>
    </div>


</div>
    </>

        );
}
export default UpdateSlider;