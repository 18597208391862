import React, { useRef, useState } from 'react';
import flower1 from "../FrameDecorationAssets/flower1.png"
import { useLocation } from 'react-router-dom';
import calender from "../FrameDecorationAssets/calender.jpeg";
import clock from "../FrameDecorationAssets/clock.png";
import weightCal from "../FrameDecorationAssets/weight-calculator.jpg";
import Hospital from "../FrameDecorationAssets/Hospital.jpeg"
import gender from "../FrameDecorationAssets/gender.jpeg";
// import leftCornerFlower from "../FrameDecorationAssets/LeftCorner.jpeg";
// import rightCornerFlower from "../FrameDecorationAssets/rightCorner.jpeg";
import redflower from "../FrameDecorationAssets/red-flower.png";
import leftCornerFlower from "../FrameDecorationAssets/LeftCornerRedFlower.png";
import rightCornerFlower from "../FrameDecorationAssets/RightCornerRedFlower.png";
import moment from "moment";


const  SecondA4=()=> {
  const { state } = useLocation();
  console.log(state.state.item.framedata[0])
  let state1=state.state.item.framedata[0];
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);
  const [initialX, setInitialX] = useState(0);
  const [initialY, setInitialY] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  // Function to handle mouse down event
  const handleMouseDown = (e) => {
      // Store the initial mouse position and image position
      setOffsetX(e.clientX);
      setOffsetY(e.clientY);
      setInitialX(e.target.offsetLeft);
      setInitialY(e.target.offsetTop);
      setIsDragging(true);

      // Attach event listener to handle mouse move event
      document.addEventListener("mousemove", handleMouseMove);
      // Attach event listener to handle mouse up event
      document.addEventListener("mouseup", handleMouseUp);
  };

 
  const handleMouseMove = (e) => {
      if (isDragging) {
        
          const newX = initialX + e.clientX - offsetX;
          const newY = initialY + e.clientY - offsetY;
          console.log(newX);
          console.log(newY);

      
          e.target.style.left = newX + "px";
          e.target.style.top = newY + "px";
      }
  };

  // Function to handle mouse up event
  const handleMouseUp = () => {
      setIsDragging(false);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
  };
  
 

  const imageRef = useRef(null);

  // Function to handle zoom based on mouse wheel event
  const handleZoomIn = (e) => {
      
          setZoomLevel(zoomLevel + 0.1); // Zoom in
    
  };
  const handleZoomOut = (e) => {
   
        setZoomLevel(zoomLevel - 0.1); // Zoom out
  
};
  return (
   <>
<div className='designFrame' style={{width:"100%"}}>
  <div className='printme' style={{width:"100%"}}>


    {/* <div className='col-12'> */}
   
        <img src={redflower} className='' alt='' style={{width:"100%",height:"130px",padding:"0"}}/>
    <p className='satisfy-regular  ' style={{fontSize:"3rem",marginBottom:"0",textAlign:"center",lineHeight:"0.7"}}  contenteditable="true"> I am {state1.baby_gender=="Girl"?"Princess":"Prince"}</p>
    <p className='itim-regular ' style={{fontSize:"5rem",color:"#bf2b28",padding:"0",textAlign:"center",marginBottom:"0",lineHeight:"1.2"}}  contenteditable="true">{state1.baby_name}</p>

    <div style={{display:"flex"}}>
    <div style={{textAlign:"center",width:"33.33%"}}>
        <p className='pacifico-regular' style={{fontSize:"2.5rem",color:"#bf2b28"}}>Born In</p>
        <img src={calender} className='img-fluid ' alt='' style={{width:"50%"}}/>
        <p className='pacifico-regular' style={{fontSize:"2.5rem",color:"#bf2b28"}}  contenteditable="true">{moment(state1.dob).format('YYYY-MM-DD')}</p>
        </div>

        <div className='' style={{display:"flex",justifyContent:"center",width:"33.33%"}} >
          <div className='' style={{position:"absolute",overflow:"hidden",borderRadius:"20px",border:"7px solid #85208c",width:"300px",height:"255px"}}>
          <img src="https://img.freepik.com/free-photo/cute-baby-born_624325-1181.jpg?t=st=1709460995~exp=1709464595~hmac=fafe5b8f262b6a904c034846872828e5385f37be0750875730aac8ea4d726af7&w=826" onMouseDown={handleMouseDown} className='img-fluid baby-image  ' alt='' style={{position:"relative",objectFit:"cover",cursor: "move", transform: `scale(${zoomLevel})`,transformOrigin: "top left"  }} ref={imageRef} onDoubleClick={handleZoomIn} onProgress={handleZoomOut}/>
          
          </div>
          
        
      </div>
      

      <div style={{textAlign:"center",width:"33.33%"}}>
        <p className='pacifico-regular' style={{fontSize:"2.5rem",color:"#bf2b28"}}>Born At</p>
        <img src={clock} className='img-fluid ' alt='clock' style={{width:"36%"}}/>
        <p className='pacifico-regular' style={{fontSize:"2.5rem",color:"#bf2b28"}}  contenteditable="true">{state1.baby_tob}</p>
        </div>

    </div>
     
 {/* <div className='col-12 p-0'> */}
 {/* <div  style={{display:"flex",justifyContent:"space-around",width:"100%"}}>
      <div className='' style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
        <div style={{textAlign:"center"}}>
        <p className='pacifico-regular' style={{fontSize:"3rem",color:"#bf2b28"}}>Born In</p>
        <img src={calender} className='img-fluid ' alt='' style={{width:"60%"}}/>
        </div>
        
      </div>
      <div className='' style={{display:"flex",justifyContent:"center"}} >
        <img src="https://img.freepik.com/free-photo/cute-baby-born_624325-1181.jpg?t=st=1709460995~exp=1709464595~hmac=fafe5b8f262b6a904c034846872828e5385f37be0750875730aac8ea4d726af7&w=826" className='img-fluid   ' style={{objectFit:"cover",borderRadius:"20px",border:"7px solid #85208c",width:"345px",height:"390px"}} alt=''/>
      </div>
      <div className='' style={{display:"flex",justifyContent:"center"}}>
        <div style={{textAlign:"center"}}>
        <p className='pacifico-regular' style={{fontSize:"3rem",color:"#bf2b28"}}>Born At</p>
        <img src={clock} className='img-fluid ' alt='clock' style={{width:"50%"}}/>
        </div>
    
      </div>

    </div> */}
 {/* </div> */}
   
{/* <div className='col-12 p-0'> */}



<div style={{display:"flex"}}>
    <div style={{textAlign:"center",width:"33.33%"}}>
    <p className='pacifico-regular' style={{fontSize:"2.5rem",color:"#bf2b28"}}>Weight</p>
    <img src={weightCal} className=' ' alt='' style={{width:"49%"}}/>
        <p className='pacifico-regular' style={{fontSize:"2.5rem",color:"#bf2b28"}}  contenteditable="true">{state1.baby_weight}<span className='protest-riot-regular' style={{fontSize:"2rem",color:"#bf2b28"}}  contenteditable="true" >Kg</span></p>
        </div>

        <div className='' style={{textAlign:"center",width:"33.33%"}} >
        <p className='pacifico-regular' style={{fontSize:"2.5rem",color:"#bf2b28"}}>Hospital</p>
        <img src={Hospital} className='' alt='hospital'  style={{width:"70%"}}/>
        <p className='protest-riot-regular' style={{fontSize:"2.2rem",color:"#bf2b28"}}  contenteditable="true">{state1.hospital_name}</p>
      </div>

      <div style={{textAlign:"center",width:"33.33%"}}>
      <p className='pacifico-regular' style={{fontSize:"2.5rem",color:"#bf2b28"}}>Gender</p>
        <img src={gender} className='img-fluid ' alt='clock' style={{width:"45%"}}/>
        <p className='protest-riot-regular' style={{fontSize:"2.2rem",color:"#bf2b28"}}  contenteditable="true">Baby {state1.baby_gender}</p>
        </div>

    </div>

{/* </div> */}
   
{/* <div className='col-12 p-0'> */}
<div className='' style={{display:"flex"}}>
      <div style={{width:"19%",display:"flex",height:"240px"}} >
        <div style={{display:"flex",justifyContent:"start",alignItems:"end"}}>
        <img src={leftCornerFlower} className='img-fluid ' alt='clock' style={{width:"100%"}}/>

        </div>
      
      </div>
      <div className=''  style={{width:"30%",display:"flex",justifyContent:"center"}}>
        {/* <div style={{textAlign:"center"}}> */}

        <div className='' style={{position:"absolute",overflow:"hidden",borderRadius:"20px",border:"7px solid #85208c",width:"30%",height:"240px"}}>
          <img src="https://img.freepik.com/free-photo/cute-baby-born_624325-1181.jpg?t=st=1709460995~exp=1709464595~hmac=fafe5b8f262b6a904c034846872828e5385f37be0750875730aac8ea4d726af7&w=826" onMouseDown={handleMouseDown} className='img-fluid baby-image  ' alt='' style={{position:"relative",objectFit:"cover",cursor: "move", transform: `scale(${zoomLevel})`,transformOrigin: "top left"  }} ref={imageRef} onDoubleClick={handleZoomIn} onProgress={handleZoomOut}/>
          
          </div>
        {/* <img src="https://img.freepik.com/free-photo/cute-baby-born_624325-1181.jpg?t=st=1709460995~exp=1709464595~hmac=fafe5b8f262b6a904c034846872828e5385f37be0750875730aac8ea4d726af7&w=826" className='img-fluid   ' style={{objectFit:"cover",borderRadius:"20px",border:"7px solid #85208c",width:"40%",height:"250px"}} alt=''/> */}
      
        {/* </div> */}
      </div>
      <div className='' style={{width:"32%"}} >
        <p className='pacifico-regular' style={{fontSize:"2.5rem",color:"#bf2b28",textAlign:"center"}}>Proud Parents</p>
        <p className='protest-riot-regular' style={{fontSize:"2.2rem",color:"#bf2b28",padding:0,lineHeight:"0.7",textAlign:"center"}}  contenteditable="true">
          Nirmala Kushwah</p>
          <p className='protest-riot-regular' style={{fontSize:"2.2rem",color:"#bf2b28",padding:0,lineHeight:"0.7",textAlign:"center"}}  contenteditable="true"> &</p> 
           <p className='protest-riot-regular' style={{fontSize:"2.2rem",color:"#bf2b28",padding:0,lineHeight:"0.7",textAlign:"center"}}  contenteditable="true"> Sooraj kushwah</p>
        </div>
      <div className='' style={{width:"19%",display:"flex",height:"240px"}}>
        <div style={{display:"flex",justifyContent:"end",alignItems:"end"}}>
        <img src={rightCornerFlower} className='img-fluid p-0' alt='' style={{width:"100%"}}/>

        </div>
    
      </div>

    </div>
{/* </div> */}
  
  {/* </div> */}
    </div>
   
   <div className='p-3'>
    <button className='btn btn-primary' onClick={()=>window.print()}> Print</button>
   </div>
</div>
   </>
  )
}

export default SecondA4