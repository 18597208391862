import Dashboard from "../../Dashboard";
import AllOrder from "./Order";

const Shipped=()=>{
    return(
        <>
<AllOrder status="Shipped"/>
        </>
    );
}
export default Shipped;