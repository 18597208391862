import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import NavBar from "../../../NavBar";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { set } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
const UpdateStaticpage=()=>{
    const {state}=useLocation();
    const [name , setName]=useState(state.item.name);
    const [description , setDescription]=useState(state.item.description);
    const [status , setStatus]=useState(state.item.status);
    const [id , setId]=useState(state.item._id);

    const navigate=useNavigate();
    // const BASE_URL = process.env.REACT_APP_BASE_URL;

    const BASE_URL=process.env.REACT_APP_BASE_URL;
    const token=localStorage.getItem("token");
    const updpage=()=>{
        const obj={
            name:name,
            description:description,
            status:status
        }

        axios
        .put(`${BASE_URL}admin/updateStaticPage/${id}`,obj,{
            headers:{
                "Content-Type":"Application/json",
                "version":"1.0.0",
                "x-access-token":`${token}`

,
            }
        }).then((response)=>{
            if(response.data.success===false)
            {
                toast.error(response.data.message);
                if(response.data.error_code==461)
                {
                    navigate("/SignIn");
                }
            }else{
                toast.success(response.data.message);
                navigate("/staticpageList")
            }
        }).catch((error)=>{
            console.log(error);
        })
    }
    useEffect(()=>{
console.log("data",description);
    },[])

    return(
        <>

<NavBar/>
        <div className='container ' >
    <div className='row d-flex justify-content-center align-items-center mt-5 '>
        <div className='col-10 p-5 adddiv' style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}} >
      <div className="row">
      <div className="col-md-6">
<label>Name</label>
<input type="text" className="form-control"
name="name"
value={name}
onChange={(e)=>setName(e.target.value)}

/>

        </div>
        <div className="col-md-8">
        <label>description</label>

        <CKEditor
                    editor={ ClassicEditor }
                    data={description}
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setDescription(data);
                        // console.log( data  );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />
{/* <input type="text" id="editor"  className="form-control"
name="description"
value={description}
onChange={(e)=>setDescription(e.target.value)}
/> */}

        </div>
        <div className="col-md-6">
        <label>Status</label>
<select name="status" className="form-control" value={status} onChange={(e)=>setStatus(e.target.value)}>
    <option  value="select">--select--</option>
    <option value="Active">Active</option>
    <option value="Inactive">Inactive</option>

</select>
        </div>

        <div className="col-md-6">
        <button type="submit" className="btn btn-sm btn-primary mt-5 ms-5"  onClick={()=>updpage()}>Save</button>

        </div>
      </div>

        </div>
    </div>


</div>
        </>
    );
}
export default UpdateStaticpage;