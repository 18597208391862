import Table from "react-bootstrap/Table";
import NavBar from "../../../NavBar";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const UpdateProduct = () => {
  const { state } = useLocation();
  const [showvarientd, setShowvarientd] = useState(false);
  const [sizes, setSizes] = useState([]);
  const [vprice, setVprice] = useState();
  const [vmrp, setVmrp] = useState();
  const [productId, setProductId] = useState(state.item._id);

  const [size, setSize] = useState("");
  const [productvarient, setProductvarient] = useState([]);
  const [verityDef, setVerityDef] = useState();
  const [productVId, setProductVId] = useState("");
  const [newProductVarients, setNewProductVarients] = useState([]);
  const [showableProductV, setShowableProductV] = useState([]);
  const [momImage, setMomImage] = useState(state.item.momImage);
  const [dadImage, setDadImage] = useState(state.item.dadImage);
  const [numberOfBabyImage, setNumberOfBabyImage] = useState(
    state.item.number_of_baby_image
  );
  const [numberOfParentsImage, setNumberOfParentsImage] = useState(
    state.item.number_of_parent_image
  );
  const [parentsImage, setParentsImage] = useState(state.item.parentImage);
  console.log("state", state.item);
  const [name, setName] = useState(state.item.name);
  const [mrp, setMrp] = useState(state.item.mrp);
  const [quantity, setquantity] = useState(state.item.quantity);
  const [price, setPrice] = useState(state.item.price);
  const [shortdes, setShortdes] = useState(state.item.short_decription);
  const [longdes, setLongdes] = useState(state.item.long_decription);
  const [file, setFile] = useState([]);
  const [shippinginfo, setShippingInfo] = useState(state.item.shipping_info);
  const [refundpolicy, setRefundPolicy] = useState(state.item.refund_policy);
  const [newarrivals, setNewarrivals] = useState(state.item.newarrivals);
  const [bestsellcheck, setBestsellcheck] = useState(state.item.bestseller);
  const [status, setStatus] = useState(state.item.status);
  const [id, setId] = useState(state.item._id);

  const navigate = useNavigate();

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [category, setCategory] = useState([]);
  const [allsubcategory, setAllSubCategory] = useState([]);
  const [hcategory, setHcategory] = useState(state.item?.HomeCategoryId);
  const [ctgryId, setCtgryId] = useState(state.item?.CategoryId);
  const [scategory, setScategory] = useState(state.item?.SubCategoryId);

  const getAllCat = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getAllCategory`, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          toast.error(response.data.message);
        } else {
          setCategory(response.data.data);
          console.log("getallcategory", response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const UpdateProduct = () => {
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("name", name);
    formData.append("mrp", mrp);
    formData.append("price", price);
    formData.append("short_decription", shortdes);
    formData.append("long_decription", longdes);
    formData.append("shipping_info", shippinginfo);
    formData.append("refund_policy", refundpolicy);

    for (let i = 0; i < file.length; i++) {
      formData.append("image", file[i]);
    }
    formData.append("CategoryId", ctgryId);
    if (scategory) {
      formData.append("SubCategoryId", scategory);
    }
    formData.append("HomeCategoryId", hcategory);
    formData.append("bestseller", bestsellcheck);
    formData.append("newarrivals", newarrivals);
    formData.append("status", status);
    formData.append("quantity", quantity);
    formData.append("number_of_baby_image", numberOfBabyImage);
    formData.append("number_of_parent_image", numberOfParentsImage);
    formData.append("momImage", momImage);
    formData.append("dadImage", dadImage);
    formData.append("parentImage", parentsImage);
    formData.append("id", id);

    axios
      .put(`${BASE_URL}admin/updateProduct`, formData, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          toast.error(response.data.message);
        } else {
          const productVariantsWithProductId = newProductVarients.map(
            (variant) => ({
              ...variant,
              product_id: id,
            })
          );
          AddProductVarients(productVariantsWithProductId);
          toast.success(response.data.message);
          setName("");
          setPrice("");
          setMrp("");
          setShortdes("");
          setLongdes("");
          setFile("");

          navigate("/ProductListing");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const AddProductVarients = (items) => {
    const token = localStorage.getItem("token");
    console.log("items", items);
    axios
      .post(`${BASE_URL}admin/addProductVarient`, items, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          console.log(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getSubCategory = () => {
    const token = localStorage.getItem("token");
    if (ctgryId) {
      axios
        .post(
          `${BASE_URL}admin/getSubCategoryByCategoryId`,
          { id: ctgryId },
          {
            headers: {
              "x-access-token": `${token}`,
              "Content-Type": "Application/json",
              Version: "1.0.0",
            },
          }
        )
        .then(function (response) {
          if (response.data.success == false) {
            setAllSubCategory([]);
          } else {
            setAllSubCategory(response.data.data);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    } else {
      console.log(
        "Category not defined. In this case cannot set the subcategory"
      );
    }
  };

  useEffect(() => {
    getSubCategory();
  }, [ctgryId]);

  const Fntosetsubc = (value, getSubCategory) => {
    setCtgryId(value);
    if (getSubCategory && typeof getSubCategory === "function") {
      setScategory("");
      getSubCategory();
    }
  };

  const getSizes = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getSize`, {
        headers: {
          "Content-Type": "Application/json",
          "x-access-token": `${token}`,
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setSizes(response.data.data);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const EditVarient = (item) => {
    let btn = document.querySelector(".addeditPV");
    btn.innerHTML = "Edit Options";
    setSize(item.size);
    console.log(size);
    setVprice(item.price);
    setVmrp(item.mrp);
    setVerityDef(item.set_default);
    setProductVId(item._id);
  };

  const addproductV = async () => {
    if (!size) {
      toast.error("Size is required");
      return;
    }
    if (!vprice) {
      toast.error("Size is required");
      return;
    }
    if (!vmrp) {
      toast.error("Size is required");
      return;
    }
    let obj = {
      size: size,
      price: vprice,
      mrp: vmrp,
      set_default: verityDef,
    };
    newProductVarients.push(obj);
    console.log("newProductVarients", newProductVarients);
    setSize("");
    setVprice("");
    setVmrp("");

    setVerityDef(false);
  };

  const AddEdit = () => {
    if (productVId) {
      UpdateProductV();
    } else {
      addproductV();
    }
  };

  const UpdateProductV = () => {
    const obj = {
      size: size,
      price: vprice,
      set_default: verityDef,
      mrp: vmrp,
      id: productVId,
    };

    const token = localStorage.getItem("token");
    axios
      .put(`${BASE_URL}admin/updateVarient`, obj, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success === false) {
          toast.error(response.data.message);
        } else {
          console.log("updated result", response.data.data);
          ProdVarients();

          toast.success(response.data.message);
          let btn = document.querySelector(".addeditPV");
          btn.innerHTML = "Add Options";
          setVprice("");
          setSize("");
          setProductVId("");
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const ProdVarients = () => {
    const token = localStorage.getItem("token");
    if (productId) {
      axios
        .get(`${BASE_URL}admin/getProductVarientByProdId/${productId}`, {
          headers: {
            "Content-Type": "Application/json",
            "x-access-token": `${token}`,
            version: "1.0.0",
          },
        })
        .then((response) => {
          if (response.data.success) {
            setProductvarient(response.data.data);
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const DeleteVarient = (id) => {
    const token = localStorage.getItem("token");
    axios
      .delete(`${BASE_URL}admin/deleteProductVarient/${id}`, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success === false) {
          toast.error(response.data.message);
        } else {
          toast.success(response.data.message);
          ProdVarients();
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    getAllCat();
    getSizes();
    ProdVarients();
  }, []);
  return (
    <>
      <NavBar />
      <div className="container ">
        <div className="row d-flex justify-content-center align-items-center mt-5">
          <div
            className="col-10 p-5"
            style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
          >
            <div className="row">
              <div className="col-md-6">
                <label className="p-2">Category</label>
                <select
                  name="category"
                  className="form-control"
                  value={ctgryId}
                  onChange={(e) => Fntosetsubc(e.target.value, getSubCategory)}
                >
                  <option value="">--select--</option>
                  {category.map((item, index) => (
                    <option value={item._id} key={index}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6">
                <label className="p-2">Subcategory</label>
                <select
                  name="subcatgeory"
                  className="form-control"
                  value={scategory}
                  onChange={(e) => setScategory(e.target.value)}
                >
                  <option value="">--select--</option>
                  {allsubcategory.map((item, index) => (
                    <option value={item._id} key={index}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-6">
                <label className="p-2">Product name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label className="p-2">MRP</label>
                <input
                  type="text"
                  className="form-control"
                  name="mrp"
                  value={mrp}
                  onChange={(e) => setMrp(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label className="p-2">Price</label>
                <input
                  type="text"
                  className="form-control"
                  name="price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label className="p-2">Quantity</label>
                <input
                  type="text"
                  className="form-control"
                  name="quantity"
                  value={quantity}
                  onChange={(e) => setquantity(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label className="p-2">Image</label>
                <input
                  type="file"
                  className="form-control"
                  name="file"
                  multiple
                  // value={file}
                  onChange={(e) => setFile(e.target.files)}
                />
                {state.item?.image?.map((item, index) => (
                  <>
                    <img
                      src={item}
                      className="img-fluid"
                      width={40}
                      height={30}
                    />
                    &nbsp;&nbsp;
                  </>
                ))}
              </div>
              <div className="col-md-6">
                <label className="p-2">Short description</label>

                <CKEditor
                  editor={ClassicEditor}
                  data={shortdes}
                  onReady={(editor) => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setShortdes(data);
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                />
              </div>
              <div className="col-md-6">
                <label className="p-2">Long description</label>

                <CKEditor
                  editor={ClassicEditor}
                  data={longdes}
                  onReady={(editor) => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setLongdes(data);
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                />
              </div>

              <div className="col-md-6">
                <label className="p-2">Shipping Info </label>

                <CKEditor
                  editor={ClassicEditor}
                  data={shippinginfo}
                  onReady={(editor) => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setShippingInfo(data);
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                />
              </div>
              <div className="col-md-6">
                <label className="p-2">Refund Policy</label>

                <CKEditor
                  editor={ClassicEditor}
                  data={refundpolicy}
                  onReady={(editor) => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setRefundPolicy(data);
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                />
              </div>
              <div className="col-md-6">
                <label className="p-2">Status</label>
                <select
                  name="status"
                  className="form-control"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="select">--select--</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>
              <div className="col-md-6 p-3">
                <label className="formleb">
                  Check mark if you want to add this product in
                </label>
                <br />
                <div className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    class="largerCheckbox"
                    onClick={(e) => setBestsellcheck(e.target.checked)}
                    checked={bestsellcheck == true ? true : false}
                  />
                  &nbsp;&nbsp;&nbsp; <span>Bestseller</span>&nbsp;&nbsp;&nbsp;
                  <input
                    type="checkbox"
                    class="largerCheckbox"
                    onClick={(e) => setNewarrivals(e.target.checked)}
                    checked={newarrivals}
                  />
                  &nbsp;&nbsp;&nbsp; <span>New Arrivals</span>
                </div>
              </div>

              <div className="col-md-6 p-3">
                <label className="formleb">
                  Check mark if you want to add this baby blood group field{" "}
                </label>
                <br />
                <div className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    class="largerCheckbox"
                    onClick={(e) => setMomImage(e.target.checked)}
                    checked={momImage}
                  />
                  &nbsp;&nbsp;&nbsp; <span>Mom picture</span>&nbsp;&nbsp;&nbsp;
                  <input
                    type="checkbox"
                    class="largerCheckbox"
                    onClick={(e) => setDadImage(e.target.checked)}
                    checked={dadImage}
                  />
                  &nbsp;&nbsp;&nbsp; <span>Dad picture</span>&nbsp;&nbsp;&nbsp;
                  <span>{dadImage}</span>
                  <input
                    type="checkbox"
                    class="largerCheckbox"
                    onClick={(e) => setParentsImage(e.target.checked)}
                    checked={parentsImage}
                  />
                  &nbsp;&nbsp;&nbsp; <span>Parents picture</span>
                  &nbsp;&nbsp;&nbsp;
                </div>
              </div>

              <div className="col-md-6 p-3">
                <label className="formleb">Type Number Of Baby Images</label>
                <br />
                <div className="d-flex align-items-center">
                  <input
                    type="number"
                    class="form-control"
                    name="numberOfBabyImage"
                    value={numberOfBabyImage}
                    onChange={(e) => setNumberOfBabyImage(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-6 p-3">
                <label className="formleb">Type Number Of Parents Images</label>
                <br />
                <div className="d-flex align-items-center">
                  <input
                    type="number"
                    class="form-control"
                    name="numberOfParentsImage"
                    value={numberOfParentsImage}
                    onChange={(e) => setNumberOfParentsImage(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div>
                  <div className="row">
                    <div className="col-md-6 p-3">
                      <label>Select Size</label>
                      <select
                        className="form-control"
                        onChange={(e) => setSize(e.target.value)}
                        value={size}
                      >
                        <option className="" value="">
                          --select--
                        </option>
                        {sizes?.map((item, index) => (
                          <>
                            <option className="" key={index} value={item.name}>
                              {item.name}
                            </option>
                          </>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6 p-3">
                      <label>Mrp</label>

                      <input
                        className="form-control"
                        type="number"
                        name="vmrp"
                        value={vmrp}
                        placeholder="Enter amount(MRP)"
                        onChange={(e) => setVmrp(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 p-3">
                      <label> price</label>
                      <input
                        type="number"
                        className="form-control"
                        name="vprive"
                        value={vprice}
                        placeholder="Enter amount(price)"
                        onChange={(e) => setVprice(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 p-3">
                      <label>
                        Check if you wanna set this varient as default?
                      </label>
                      <br />
                      <input
                        type="checkbox"
                        className="largerCheckbox"
                        placeholder="set to default"
                        onClick={(e) => setVerityDef(e.target.checked)}
                        checked={verityDef}
                      />
                    </div>
                  </div>
                </div>
                <button
                  className="btn btn-primary btn-sm p-2 addeditPV my-3"
                  onClick={() => AddEdit()}
                >
                  {" "}
                  Add Options
                </button>

                <div className="col">
                  <Table striped bordered hover>
                    {productvarient?.length > 0 ? (
                      <>
                        {" "}
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Size</th>
                            <th>MRP</th>

                            <th>Price</th>

                            <th>default</th>
                            <th>Edit</th>
                            <th>Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productvarient.length > 0
                            ? productvarient.map((item, index) => (
                                <>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.size}</td>
                                    <td>{item.mrp}</td>
                                    <td>{item.price}</td>
                                    <td>
                                      {item.set_default ? "True" : "false"}
                                    </td>
                                    <td onClick={() => EditVarient(item)}>
                                      {item.product_id ? (
                                        <i className="fa fa-edit"></i>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td onClick={() => DeleteVarient(item._id)}>
                                      {item.product_id ? (
                                        <i className="fa fa-trash"></i>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                  </tr>
                                </>
                              ))
                            : ""}
                        </tbody>
                      </>
                    ) : (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "200px", width: "100%" }}
                      >
                        <div>No record Added</div>
                      </div>
                    )}
                  </Table>

                  {/* newly added varient  */}
                  {newProductVarients.length > 0 ? (
                    <>
                      <h3>Newly added varient</h3>
                      <Table striped bordered hover>
                        {newProductVarients?.length > 0 ? (
                          <>
                            {" "}
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Size</th>
                                <th>MRP</th>
                                <th>Price</th>
                                <th>default</th>
                              </tr>
                            </thead>
                            <tbody>
                              {newProductVarients.length > 0
                                ? newProductVarients.map((item, index) => (
                                    <>
                                      <tr>
                                        <td>{index + 1}</td>
                                        <td>{item.size}</td>
                                        <td>{item.mrp}</td>
                                        <td>{item.price}</td>
                                        <td>
                                          {item.set_default ? "True" : "false"}
                                        </td>
                                      </tr>
                                    </>
                                  ))
                                : ""}
                            </tbody>
                          </>
                        ) : (
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ height: "200px", width: "100%" }}
                          >
                            <div>No record Added</div>
                          </div>
                        )}
                      </Table>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                {/* </div> */}
              </div>

              <button
                type="submit"
                className="btn btn-sm btn-primary mt-3 ms-3"
                style={{ width: "60px" }}
                onClick={() => UpdateProduct()}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UpdateProduct;
