import Dashboard from "./Dashboard";

import Category from "./Pages/Module/Categories/Category";
import SubCategory from "./Pages/Module/Subcategory/subCategory";
import AddProduct from "./Pages/Module/product/Addproduct";
import ProductListing from "./Pages/Module/product/ProductListing";
import { route, createBrowserRouter, RouterProvider } from "react-router-dom";
import Slider from "./Pages/Module/Slider/Slider";
import Banner from "./Pages/Module/Homebanner/Banner";
import WebsiteInfo from "./Pages/Module/WebsiteInfo";
import AllOrder from "./Pages/Module/Order";
import NewOrder from "./Pages/Module/NewOrder";
import Process from "./Pages/Module/Process";
import Shipped from "./Pages/Module/Shipped";
import Delivered from "./Pages/Module/Delivered";
import Failed from "./Pages/Module/Failed";
import List from "./Pages/Module/List";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboardd from "./Dashboard";
import SignIn from "./Pages/Module/SignIn";
import Login from "./Auth/Login";
import ShippingCharges from "./Pages/Module/shippingCharges";
import AllUserList from "./Pages/Module/Alluserlist";
import StaticPageList from "./Pages/Module/StaticPages/staticPageList";
import Changepswd from "./Pages/Module/Changepassword";
import EditCategory from "./Pages/Module/Categories/Editcategory";
import UpdateProduct from "./Pages/Module/product/UpdateProduct";
import Updatesubcategory from "./Pages/Module/Subcategory/Updatesubcategory";
import UpdateSlider from "./Pages/Module/Slider/UpdateSlider";
import UpdateStaticpage from "./Pages/Module/StaticPages/UpdateStaticpage";
import Offer from "./Pages/Module/Offer/AddOffer";
import UpdateOffer from "./Pages/Module/Offer/UpdateOffer";
import Testimonial from "./Pages/Module/Testimonial/Testimonial";
import UpdateSize from "./Pages/Module/Size/UpdateSize";
import Size from "./Pages/Module/Size/Size";

import Viewuser from "./Pages/Module/ViewUser";
import OrderDetails from "./Pages/Module/OrderDetails";
import ReturnOrders from "./Pages/Module/ReturnOrders";
import ReplaceOrders from "./Pages/Module/ReplaceOrders";
import PrintInvoice from "./Pages/Module/PrintInvoice";
import FrameInfo from "./Pages/Module/FrameInfo";
import FirstA4 from "./Pages/Module/FrameDesigns/FirstA4";
import FirstA3 from "./Pages/Module/FrameDesigns/FirstA3";
import FirstA5 from "./Pages/Module/FrameDesigns/FirstA5";
import SecondA3 from "./Pages/Module/FrameDesigns/SecondA3";
import SecondA4 from "./Pages/Module/FrameDesigns/SecondA4";
import SecondA5 from "./Pages/Module/FrameDesigns/SecondA5";
import ThirdA3 from "./Pages/Module/FrameDesigns/ThirdA3";
import FourthA3 from "./Pages/Module/FrameDesigns/FourthA3";
import FourthA4 from "./Pages/Module/FrameDesigns/FourthA4";
import FourthA5 from "./Pages/Module/FrameDesigns/FourthA5";
import ThirdA4 from "./Pages/Module/FrameDesigns/ThirdA4";
import ThirdA5 from "./Pages/Module/FrameDesigns/ThirdA5";
function App() {
  const router = createBrowserRouter([
    {
      path: "/SignIn",
      element: <SignIn />,
    },
    {
      path: "/",
      element: <Dashboard />,
    },
    {
      path: "/view-user",
      element: <Viewuser />,
    },
    {
      path: "/order-details",
      element: <OrderDetails />,
    },
    {
      path: "/Category",
      element: <Category />,
    },
    {
      path: "/SubCategory",
      element: <SubCategory />,
    },

    {
      path: "/AddProduct",
      element: <AddProduct />,
    },
    {
      path: "/ProductListing",
      element: <ProductListing />,
    },
    {
      path: "/WebsiteInfo",
      element: <WebsiteInfo />,
    },
    {
      path: "/Slider",
      element: <Slider />,
    },
    {
      path: "/Banner",
      element: <Banner />,
    },
    {
      path: "/AllOrder",
      element: <AllOrder />,
    },
    {
      path: "/new-order",
      element: <NewOrder />,
    },
    {
      path: "/process",
      element: <Process />,
    },
    {
      path: "/shipped",
      element: <Shipped />,
    },
    {
      path: "/delivered",
      element: <Delivered />,
    },
    {
      path: "/failed",
      element: <Failed />,
    },
    {
      path: "/return-orders",
      element: <ReturnOrders />,
    },
    {
      path: "/replace-orders",
      element: <ReplaceOrders />,
    },
    {
      path: "/List",
      element: <List />,
    },
    {
      path: "/shippingcharges",
      element: <ShippingCharges />,
    },
    {
      path: "/alluserlist",
      element: <AllUserList />,
    },
    {
      path: "/staticpageList",
      element: <StaticPageList />,
    },
    {
      path: "/update-staticpage",
      element: <UpdateStaticpage />,
    },

    //   {
    // path:"/add-static-page",
    // element:<AddStaticPage/>
    //   },

    {
      path: "/change-password",
      element: <Changepswd />,
    },
    {
      path: "/edit_category",
      element: <EditCategory />,
    },
    {
      path: "/update-Product",
      element: <UpdateProduct />,
    },
    {
      path: "/Update-subcategory",
      element: <Updatesubcategory />,
    },

    {
      path: "/update-slider",
      element: <UpdateSlider />,
    },
    {
      path: "/add-offer",
      element: <Offer />,
    },
    {
      path: "/update-offer",
      element: <UpdateOffer />,
    },
    {
      path: "/testimonial",
      element: <Testimonial />,
    },
    {
      path: "/update-size",
      element: <UpdateSize />,
    },

    {
      path: "/add-size",
      element: <Size />,
    },

    {
      path: "/print-invoice",
      element: <PrintInvoice />,
    },
    {
      path: "/frame-info",
      element: <FrameInfo />,
    },
    {
      path: "/first-frame-A4-design",
      element: <FirstA4 />,
    },
    {
      path: "/first-frame-A3-design",
      element: <FirstA3 />,
    },
    {
      path: "/first-frame-A5-design",
      element: <FirstA5 />,
    },
    {
      path: "/second-frame-A3-design",
      element: <SecondA3 />,
    },
    {
      path: "/second-frame-A4-design",
      element: <SecondA4 />,
    },
    {
      path: "/second-frame-A5-design",
      element: <SecondA5 />,
    },
    {
      path: "/third-frame-A3-design",
      element: <ThirdA3 />,
    },
    {
      path: "/third-frame-A4-design",
      element: <ThirdA4 />,
    },
    {
      path: "/third-frame-A5-design",
      element: <ThirdA5 />,
    },
    {
      path: "/fourth-frame-A3-design",
      element: <FourthA3 />,
    },
    {
      path: "/fourth-frame-A4-design",
      element: <FourthA4 />,
    },
    {
      path: "/fourth-frame-A5-design",
      element: <FourthA5 />,
    },
  ]);
  return (
    <>
      <ToastContainer />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
