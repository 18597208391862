import Dashboard from "../../Dashboard";
import AllOrder from "./Order";

const Process=()=>{
    return(
        <>
  <AllOrder status="Process"/>
        </>
    );
}
export default Process;